import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./Checkout.module.css";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { label } from "../../helpers/language";
import Loader from "../../components/loader/Loader";
import CountrySelect from "../../components/select/CountrySelect";
import { closeCart, setCountry } from "../../redux/slice";
import CheckoutSummary from "./Checkout_Cart";
import { client } from "../../helpers/config/api";
import { size } from "lodash";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const { order, lng, country } = useSelector((state) => state.global);
  const { cart } = order;

  useEffect(() => {
    if (size(cart) === 0) {
      navigate("/");
    }
    dispatch(closeCart(false));
  }, []);

  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [url, setUrl] = useState("");
  const [isRedirecting, setIsRedirecting] = useState(false);

  let checkoutSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, "min3")
      .max(50, "max50")
      .required("required"),
    email: Yup.string().email("mailError").required("required"),
    phone: Yup.string().min(10, "min10").required("required"),
    address: Yup.string()
      .min(3, "min3")
      .max(100, "max100")
      .required("required"),
    city: Yup.string().min(3, "min3").max(100, "max100").required("required"),
    zip: Yup.number("onlyNumber").required("required"),
    termsAndConditions: Yup.bool().oneOf([true], "agreeConditions"),
  });

  const changeCountry = (selectedCountry) => {
    dispatch(setCountry(selectedCountry));
  };

  function processOrder(data) {
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadPercentage(percentCompleted);
      },
    };
    setLoading(true);
    client
      .post("/order/pending", data, config)
      .then((response) => {
        if (response.data.isSuccess) {
          if (size(response.data.url) > 0) {
            setUrl(response.data.url);
            setIsRedirecting(true);
            setTimeout(() => {
              window.location.href = response.data.url;
            }, 5000);
          } else {
            navigate("/payment/success");
          }
        } else {
          setLoading(false);
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        toast.error("Please make sure to fill with correct data!");
      });
  }

  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    if (size(url) > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 1) {
            return prevSeconds - 1;
          }
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [url]);

  return size(url) === 0 ? (
    <div className={style.checkoutWrapper}>
      <div className={`container `}>
        <ToastContainer />
        {loading && (
          <div>
            <Loader isCheckout={true} />
            {uploadPercentage > 0 && (
              <div className={style.progressBar}>
                <div style={{ width: `${uploadPercentage}%` }}>
                  {uploadPercentage}%
                </div>
              </div>
            )}
          </div>
        )}
        <div className={`grid2 ${style.checkout}`}>
          <Formik
            className={"column"}
            initialValues={{
              country: country,
              paymentType: "delivery",
              firstName: "",
              email: "",
              address: "",
              city: "",
              zip: "",
              phone: "",
              termsAndConditions: false,
            }}
            validationSchema={checkoutSchema}
            onSubmit={(values, { resetForm }) => {
              const data = values;
              data.order = order.cart;
              processOrder(data);
            }}
          >
            {({ errors, values, touched, setFieldValue }) => (
              <Form className={style.checkoutForm}>
                {validationError.length > 0 && (
                  <p className="errorMsg">{label[lng][validationError]}</p>
                )}
                <div className="sectionWrapper">
                  <p>{label[lng].personalInformation}</p>
                  <div className="grid2">
                    <div className="inputWrapper">
                      <label>{label[lng].firstName}</label>
                      <Field
                        name="firstName"
                        placeholder="Name and Surname"
                        autoComplete="off"
                        className={errors.firstName && style.inputError}
                      />
                      {errors.firstName && touched.firstName && (
                        <p className="errorTxt">
                          {label[lng][errors.firstName]}
                        </p>
                      )}
                    </div>
                    <div className="inputWrapper">
                      <label>Email</label>
                      <Field
                        name="email"
                        placeholder="info@bolt-box.com"
                        autoComplete="off"
                        className={errors.email && style.inputError}
                      />
                      {errors.email && touched.email && (
                        <p className="errorTxt">{label[lng][errors.email]}</p>
                      )}
                    </div>
                  </div>
                  <div className="grid1">
                    <div className="inputWrapper">
                      <label>{label[lng].phoneNumber}</label>
                      <PhoneInput
                        name={"phone"}
                        placeholder="Enter phone number"
                        value={value}
                        onChange={(value) => {
                          setFieldValue("phone", value);
                        }}
                      />
                      {errors.phone && (
                        <p className="errorTxt">{label[lng][errors.phone]}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={`${style.lastSection} sectionWrapper`}>
                  <p>{label[lng].shippingAddress}</p>
                  <div className="grid1">
                    <div className="inputWrapper">
                      <label>{label[lng].country}</label>
                      <CountrySelect
                        selectCountry={(selectedCountry) => {
                          setFieldValue("country", selectedCountry.country);
                          changeCountry(selectedCountry);
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid2">
                    <div className="inputWrapper">
                      <label>{label[lng].city}</label>
                      <Field
                        name="city"
                        placeholder="City"
                        autoComplete="off"
                        className={errors.city && style.inputError}
                      />
                      {errors.city && touched.city && (
                        <p className="errorTxt">{label[lng][errors.city]}</p>
                      )}
                    </div>
                    <div className="inputWrapper">
                      <label>ZIP</label>
                      <Field
                        name="zip"
                        placeholder="Postal Code"
                        autoComplete="off"
                        className={errors.zip && style.inputError}
                      />
                      {errors.zip && touched.zip && (
                        <p className="errorTxt">{label[lng][errors.zip]}</p>
                      )}
                    </div>
                  </div>
                  <div className="grid1">
                    <div className="inputWrapper">
                      <label>{label[lng].address}</label>
                      <Field
                        name="address"
                        placeholder="Address"
                        autoComplete="off"
                        className={errors.address && style.inputError}
                      />
                      {errors.address && touched.address && (
                        <p className="errorTxt">{label[lng][errors.address]}</p>
                      )}
                    </div>
                  </div>
                </div>
                {values.country === "Kosovo" && (
                  <>
                    <label>{label[lng].selectPayment}</label>
                    <div className={style.paymentDis}>
                      <div className="radioOption">
                        <Field type="radio" name="paymentType" value="online" />
                        <label>
                          <div>
                            {label[lng].onlinePayment}
                            <img src="/images/general/pay.png" alt="paysera" />
                          </div>
                        </label>
                      </div>
                      <div className="radioOption">
                        <Field
                          type="radio"
                          name="paymentType"
                          value="delivery"
                        />
                        <label>
                          <div>
                            {label[lng].payOnDelivery}
                            <img src="/images/general/free.png" alt="free" />
                          </div>
                        </label>
                      </div>
                      {errors.paymentType && touched.paymentType && (
                        <p className="errorTxt">
                          {label[lng][errors.paymentType]}
                        </p>
                      )}
                    </div>
                  </>
                )}
                <div className="grid1">
                  <label>
                    <Field type="checkbox" name="termsAndConditions" />
                    {label[lng].agreeTerms}
                  </label>
                  {errors.termsAndConditions && (
                    <p className="errorTxt">
                      {label[lng][errors.termsAndConditions]}
                    </p>
                  )}
                </div>

                <button type="submit" className="btn">
                  {label[lng].buy}
                </button>
              </Form>
            )}
          </Formik>
          <CheckoutSummary />
        </div>
      </div>
    </div>
  ) : (
    <div className={style.checkoutWrapper}>
      <div className={`container `}>
        <div className="grid2">
          <div className={style.paymentWrapper}>
            <div className={style.paymentLink}>
              <h1>One step away!</h1>
              <p>
                You will be redirected for online payments in {seconds} seconds.
              </p>
              <p>If not, please click this</p>{" "}
              <a href={url} className={style.link}>
                Click Here
              </a>
              <p>
                Online payments are processed through Paysera.
                <a href="https://paysera.com/" target="_blank" rel="noreferrer">
                  Paysera
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
