import React from "react";

import Cart from "../../layout/cart/Cart";
import PrivacyHeading from "../../components/privacyHeading/PrivacyHeading";
import { useSelector } from "react-redux";
import { label } from "../../helpers/language";

const Refund = () => {
  const { lng } = useSelector((state) => state.global);


  
  return (
    <>
  
      <PrivacyHeading title={label[lng].refundHeading} text=""/>
      <Cart>
      <div className="privacy pb-50">
        <p className="pb-20">
       
          
              <b>{label[lng].refundProducts}</b>
          
         
        </p>
        <p>
        { label[lng].refundDes1}
        </p>
        <p className="pt-20">
        { label[lng].refundDes2}
        </p>
        <p className="pt-20">
        { label[lng].refundDes3}
        </p>
        <p className="pt-20">
        { label[lng].refundDes4}
        </p>
        <p className="pt-20">
        
          {label[lng].refundDes5}
        </p>
        <p className="pt-20">
        {label[lng].returnDes6}
        </p>
      </div>
      </Cart>
    </>
  );
};

export default Refund;
