import { get, map } from "lodash";
import { IMAGE_URL } from "../../../helpers/constants";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import style from "../Shop.module.css"
import { useSelector } from "react-redux";
import { label } from "../../../helpers/language";
const CanvasShop = ({ products }) => {
  const productImages = get(products, "assets.theme.value", {});
  const { shippingType ,lng} = useSelector((state) => state.global);

  return (
    <div className={style.filterDis}>
      {map(productImages, (value, key) => (
        
        <Link to={`/order/portrait?theme=${value}`} key={`link-canvas-${key}`}>
             <div className={style.cardImage}>
             <img
            loading="lazy"
            src={`${IMAGE_URL}assets/canvas-1-${value}.webp`}
            alt="Portait"
            className={style.black}
          />
          </div>
          <div className={style.cardContent}>
        <div>
          <Rating size={20} initialValue={5} readonly={true} />
          <p>The {value}</p>
          <p><span>From</span> {get(products, `initialPrice[${shippingType}]`)}&euro;</p>
        </div>
        <p className={style.cardBtn}>{label[lng].orderNow}</p>
        
      </div>
      { value === 'cosmic' || value === 'bossy' || value === 'superhero' ?  <p
          className={
            style.bestSeller
          }
        >
          BEST SELLER
        </p> : ""}
        </Link>
      ))}
    </div>
  );
};

export default CanvasShop;
