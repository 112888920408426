import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import style from "../Order.module.css";
import { useDispatch, useSelector } from "react-redux";
import { client } from "../../../helpers/config/api";
import { Formik, Form, Field, FieldArray } from "formik";
import { label } from "../../../helpers/language";
import ImageUpload from "../../../components/order/ImageUpload";
import { Color, Size } from "../../../components/order/Attributes";
import { get } from "lodash";
import { addToCart } from "../../../redux/slice";
import { ToastContainer } from "react-toastify";
import { validationSchema } from "./validation";
import { DEFAULT_HODDIE, HoodieConfig } from "../../../@boltbox/orderConfig";
import {
  BALKAN,
  WORLDWIDE,
  calculateHoodiePrice,
} from "../../../@boltbox/prices";
import { IMAGE_URL } from "../../../helpers/constants";
import OrderWrapper from "../OrderWrapper";
import { Rating } from "react-simple-star-rating";
import { useNavigate, useSearchParams } from "react-router-dom";
const Hoodie = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lng, shippingType } = useSelector((state) => state.global);
  const [loading, setLoading] = useState(true);
  const [hoodieConfiguration, setHoodieConfiguration] = useState(null);
  const [redirectToCheckout, setRedirectToCheckout] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getHoodieConfig();
  }, []);

  function getHoodieConfig() {
    client.get(`/product/hoodie`).then((response) => {
      const { data } = response;
      if (data.isSuccess) {
        setHoodieConfiguration(data.data);
        setLoading(false);
      }
    });
  }

  return (
    <OrderWrapper loading={loading}>
      <Formik
        initialValues={HoodieConfig(searchParams.get("color") || "black")}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          const hoodieData = values;

          hoodieData.price = {
            [BALKAN]: calculateHoodiePrice(BALKAN, hoodieConfiguration, values),
            [WORLDWIDE]: calculateHoodiePrice(
              WORLDWIDE,
              hoodieConfiguration,
              values
            ),
          };
          hoodieData.preview = `${IMAGE_URL}${get(
            hoodieConfiguration,
            `assets.color.image.${values.color}`
          )}`;
          dispatch(addToCart(hoodieData));

          resetForm();
          if (redirectToCheckout) {
            navigate("/checkout");
            setRedirectToCheckout(false);
          }
        }}
      >
        {({ submitForm, setFieldValue, errors, values, touched }) => (
          <Form>
            <ToastContainer />
            <div className={style.productInfoModel}>
              <div>
                <div className={`${style.imageWall} ${style.hoodieMain}`}>
                  <img
                    loading="lazy"
                    src={`${IMAGE_URL}${get(
                      hoodieConfiguration,
                      `assets.color.image.${values.color}`
                    )}`}
                    alt="Portait"
                  />
                </div>
              </div>
              <div className={style.orderBody}>
                <h2> {label[lng].orderHoodie}</h2>
                <div className={style.subTotalPrice}>
                  <Rating size={20} initialValue={5} readonly={true} />
                  <h1>
                    {label[lng].price}:
                    <span>
                      {" "}
                      {calculateHoodiePrice(
                        shippingType,
                        hoodieConfiguration,
                        values
                      )}{" "}
                      &euro;
                    </span>
                  </h1>
                </div>
                <FieldArray
                  name="data"
                  render={(arrayHelpers) => (
                    <div>
                      <p>{label[lng].animalDetails}</p>
                      {values &&
                        values.data.map((item, index) => (
                          <div key={index}>
                            <Field
                              name={`data[${index}].name`}
                              type="text"
                              placeholder="Name"
                              className={`${
                                errors.data?.[index]?.name &&
                                touched.data?.[index]?.name
                                  ? style.borderError
                                  : ""
                              }`}
                            />
                            {errors.data?.[index]?.name &&
                              touched.data?.[index]?.name && (
                                <div className={style.error}>
                                  {errors.data[index].name}
                                </div>
                              )}
                            <ImageUpload
                              index={index}
                              images={item.images}
                              setFieldValue={(values) => {
                                setFieldValue(`data[${index}].images`, values);
                              }}
                            />
                            {errors.data?.[index]?.images &&
                              touched.data?.[index]?.images && (
                                <div className={style.error}>
                                  {errors.data[index].images}
                                </div>
                              )}
                          </div>
                        ))}
                    </div>
                  )}
                />
                <Color
                  colors={get(hoodieConfiguration, "assets.color.value", [])}
                  active={values.color}
                  select={(color) => {
                    setSearchParams({ color: color });
                    setFieldValue("color", color);
                  }}
                />
                <Size
                  size={get(hoodieConfiguration, "assets.size.value", [])}
                  active={values.size}
                  select={(size) => {
                    setFieldValue("size", size);
                  }}
                  lng={lng}
                />

                <div className={style.shopBtn}>
                  <button type={"submit"} className="btn">
                    {label[lng].addCart}
                  </button>
                  <button
                    className={style.shopColorBtn}
                    onClick={() => {
                      setRedirectToCheckout(true);
                      submitForm();
                    }}
                  >
                    {label[lng].buyNow}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </OrderWrapper>
  );
};

export default Hoodie;
