import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Checkout from "../pages/checkout/Checkout";
import Home from "../pages/home/Home";
import Demo from "../pages/demo/Demo";
import PaymentMessage from "../pages/payment/PaymentMessage";
import Privacy from "../pages/privacy/Privacy";
import Terms from "../pages/privacy/Terms";
import Refund from "../pages/privacy/Refund";
import Collection from "../pages/peekInside/peekInside";
import OurStory from "../pages/story/story";
import Contact from "../pages/contact/contact";
import Portrait from "../pages/order/portrait/canva";
import Hoodie from "../pages/order/hoodie";
import Mug from "../pages/order/mug";
import Faqs from "../pages/faqPage/FaqPage";
import Shop from "../pages/shop/Shop";
import Oil from "../pages/order/portrait/oil";
import Line from "../pages/order/portrait/line";
import Bag from "../pages/order/bag";
import Case from "../pages/order/case";
import BlankPage from "../pages/home/blankPage";

function RouteConfig() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      {/* <Route path="/" element={<BlankPage />} /> */}
      <Route path="/" element={<Home />} />
      <Route path="/order/portrait" element={<Portrait />} />
      <Route path="/order/hoodie" element={<Hoodie />} />
      <Route path="/order/mug" element={<Mug />} />
      <Route path="/order/oil" element={<Oil />} />
      <Route path="/order/line" element={<Line />} />
      <Route path="/order/bag" element={<Bag />} />
      <Route path="/order/case" element={<Case />} />

      
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/payment/:type" element={<PaymentMessage />} />
      <Route path="/demo" element={<Demo />} />
      <Route path="/policy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/refund" element={<Refund />} />
      <Route path="/theme" element={<Collection />} />

      <Route path="/contact-us" element={<Contact />} />
      <Route path="/faq" element={<Faqs />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/OurStory" element={<OurStory />} />
      <Route path="/*" element={<Home />} />
    </Routes>
  );
}

export default RouteConfig;
