import { useLocation } from "react-router";
import Footer from "./layout/footer/Footer";
import Header from "./layout/header/Header";
import RouteConfig from "./router";
import MainModal from "./components/modal/MainModal";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setCountry, setProducts } from "./redux/slice";
import { Link } from "react-router-dom";
import Lottie from "react-lottie-player";
import dog from "../src/helpers/lotties/dgg.json";
import { client } from "./helpers/config/api";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [, setLoading] = useState(true);
  const { country } = useSelector((state) => state.global);

  const showComponent = () => {
    if (location.pathname === "/payment/failed") return false;
    if (location.pathname === "/payment/success") return false;
    if (country && country.length === 0) return true;
    return !isValidJSON(localStorage.getItem("country"));
  };

  function isValidJSON(countryData) {
    try {
      const parsedData = JSON.parse(countryData);

      if (
        typeof parsedData === "object" &&
        parsedData !== null &&
        "country" in parsedData &&
        "shippingType" in parsedData &&
        typeof parsedData.country === "string" &&
        ["balkan", "worldwide"].includes(parsedData.shippingType)
      ) {
        return true;
      }

      return false;
    } catch (error) {
      localStorage.removeItem("country");
      return false;
    }
  }

  useEffect(() => {
    console.log("Hello from BoltBox!");
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (location.pathname !== "/checkout") {
        const confirmationMessage = "All products in the cart will be deleted.";
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    if (location.pathname !== "/checkout") {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location.pathname]);

  useEffect(() => {
    const countryData = localStorage.getItem("country");
    if (countryData && isValidJSON(countryData)) {
      dispatch(setCountry(JSON.parse(countryData)));
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [dispatch]);

  useEffect(() => {
    client.get(`/product`).then((response) => {
      const { data } = response;
      if (data.isSuccess) {
        dispatch(setProducts(data.data));
      }
      setLoading(false);
    });
  }, [dispatch]);

  return (
    <>
      {location.pathname.includes("/order") ||
      location.pathname.includes("/checkout") ? (
        ""
      ) : (
        <Link to="/order/portrait" className="startNow">
          <Lottie
            loop
            animationData={dog}
            play
            className="lottie-container"
            style={{ width: 150, height: 150 }}
          />
          <div className="cloud">
            <img src="/images/general/cloud2.webp" alt="cloud" />
            <div className="text">CLAIM SURPRISE</div>
          </div>
        </Link>
      )}

      <Header />
      <RouteConfig />
      <Footer />
      {showComponent() && <MainModal />}
    </>
  );
}

export default App;
