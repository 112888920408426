import React from "react";
import style from "./reverse.module.css";
import { Link } from "react-router-dom";
import { label } from "../../helpers/language";
import { useSelector } from "react-redux";
const Reverse = () => {
  const { lng } = useSelector((state) => state.global);
  const images = [
    {
      image: "/images/general/pet.webp",
      title: label[lng].modernTitle,
      content: label[lng].modernDescription,
    },
    {
      image: "/images/general/pc.webp",
      title: label[lng].realArtistTitle,
      content: label[lng].realArtistDes,
      class: style.cardsReverse,
    },
  ];
  return (
    <div>
      {images.map((item, index) => {
        return (
          <div className={` ${style.cardsDisplay} ${item.class}`} key={index}>
            <div className="w49">
              <h1>{item.title}</h1>
              <p>{item.content}</p>
              <Link to="/shop">{label[lng].cartShop}</Link>
            </div>
            <div className="w49">
              <img src={item.image} className="w-100"  alt="card"/>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Reverse;
