import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import style from './NewsLetter.module.css';
import { label } from '../../helpers/language';
import { useSelector } from 'react-redux';

const NewsletterSignup = () => {
  const { lng } = useSelector((state) => state.global);
  return (
    <div className={`container ${style.newsLetter}`}>
      <p className={style.text}>{ label[lng].newsleeter}</p>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={Yup.object({
          email: Yup.string().email('Invalid email address').required('Enter a valid e-mail address'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          // Handle form submission
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className={style.form}>
            <div>

            <Field
              type="email"
              name="email"
              placeholder="Enter your email"
              className={style.input}
            />
       
            <button type="submit" disabled={isSubmitting} className={style.button}>
              Sign Up
            </button>
            </div>
            <ErrorMessage name="email" component="div" className={style.error} />
          </Form>
          
        )}
      </Formik>
    </div>
  );
};

export default NewsletterSignup;
