import HeadCrumbs from "../../components/crumbs/HeadCrumbs";
import HowItWorks from "../../layout/helpers/HowItWorks";
import Reverse from "../../components/reverse/reverse";
import Slider from "../../components/slider/slider";
import Products from "../../layout/products/Products";
import Lottie from "react-lottie-player";
import loader from "../../helpers/lotties/loader.json";

const OrderWrapper = ({ children, loading = false }) => {
  if (loading) {
    return (
      <div className="mainLoader">
        <Lottie
          loop
          animationData={loader}
          play
          className="lottie-container"
          style={{ width: 150, height: 150 }}
        />
        ;
      </div>
    );
  }

  return (
    <>
      <div className={`container`}>
        <HeadCrumbs title="Order" link="/order" />
        <div>{children}</div>
        </div>
        <Products />
        <div className={`container`}>
        <Reverse />
        <HowItWorks />
        </div>
      <Slider />
    </>
  );
};

export default OrderWrapper;
