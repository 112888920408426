import { get, map } from "lodash";
import { IMAGE_URL } from "../../../helpers/constants";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import style from "../Shop.module.css"
import { useSelector } from "react-redux";
import { label } from "../../../helpers/language";
const OilShop = ({ products }) => {
  const productImages = get(products, "assets.petNo.image", {});
  const { shippingType ,lng} = useSelector((state) => state.global);

  return (
    <div className={`${style.filterDis} ${style.paintDis}`}>
      {map(productImages, (value, key) => (
        <Link to={`/order/oil?petNo=${key}`} key={`link-oil-${key}`}>
           

            <div className={style.cardImage}>
          <img loading="lazy" src={`${IMAGE_URL}${value}`} className={style.black} alt="Portait" />
          </div>
          <div className={style.cardContent}>
        <div>
          <Rating size={20} initialValue={5} readonly={true} />
          <p>Modern Pet Portrait</p>
          <p><span>From</span> {get(products, `initialPrice[${shippingType}]`)}&euro;</p>
        </div>
        <p className={style.cardBtn}>{label[lng].orderNow}</p>

      
      </div>

      {key === '1' ?  <p
          className={
            style.bestSeller
          }
        >
          BEST SELLER
        </p> : ""}
        </Link>
      ))}
    </div>
  );
};

export default OilShop;
