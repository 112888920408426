
import style from "./peekInside.module.css";
import React, { useRef, useState } from "react";


// import SubTitle from "../Components/Layout/subTitle";
import { Link } from "react-router-dom";
import Reverse from "../../components/reverse/reverse";
import Slider from "../../components/slider/slider";

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import { useSelector } from "react-redux";
import { label } from "../../helpers/language";

const Collection = () => {
  const { lng } = useSelector((state) => state.global);

  const [itemsToShow, setItemsToShow] = useState(8);
  const portraitRef = useRef(null);
  // Determine the number of items to display
  
  const modern =[

    {
      image: "/images/general/eno.webp",
    },
    {
      image: "/images/general/bruna.webp",
    },
    {
      image: "/images/general/kam.webp",
    },
    {
      image: "/images/general/miley.webp",
    },
  ]
  
  const line =[
  
    {
      image: "/images/general/1-line.webp",
    },
    {
      image: "/images/general/2-line.webp",
    },
    {
      image: "/images/general/3-line.webp",
    },
  
  ]
  const portrait = [
  
    {
      image: "/images/general/21.webp",
    },
    {
      image: "/images/general/22.webp",
    },
    
    {
      image: "/images/general/23.webp",
    },
    {
      image: "/images/general/24.webp",
    },
    {
      image: "/images/general/1.webp",
    },
    {
      image: "/images/general/2.webp",
    },
    {
      image: "/images/general/3.webp",
    },
    {
      image: "/images/general/4.webp",
    },
    {
      image: "/images/general/5.webp",
    },
    {
      image: "/images/general/6.webp",
    },
    {
      image: "/images/general/7.webp",
    },
    {
      image: "/images/general/8.webp",
    },
    {
      image: "/images/general/9.webp",
    },
    {
      image: "/images/general/10.webp",
    },
    {
      image: "/images/general/25.webp",
    },
    {
      image: "/images/general/12.webp",
    },
    {
      image: "/images/general/13.webp",
    },
    {
      image: "/images/general/14.webp",
    },
    {
      image: "/images/general/15.webp",
    },
    {
      image: "/images/general/16.webp",
    },
    {
      image: "/images/general/17.webp",
    },
    {
      image: "/images/general/18.webp",
    },  {
      image: "/images/general/19.webp",
    },
    {
      image: "/images/general/20.webp",
    },
   
    ];
   
  
    const handleViewMore = () => {
      if (itemsToShow + 8 >= portrait.length) {
        setItemsToShow(portrait.length);
      } else {
        setItemsToShow(itemsToShow + 8);
      }
    };
  
    const handleViewLess = () => {
      setItemsToShow(8);
      portraitRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <>
            <div className={style.collectionBck}>
            <div>
                <img src={"/images/general/img3.webp"} alt="color" className={style.collectionBanner} loading="lazy" />
            </div>
         
            <div className="container ">
            <h1 className={style.mainHeader}>{label[lng].modernPortrait}</h1>
            <div className={`grid4 ${style.collectionImage}`}>
            {modern.map((item, index) => {
              return (
                <Link to="/order/oil?petNo=1" className={style.homeCard} key={index}>
                  <img src={item.image} alt="theme" className={style.w100} />
                </Link>
              );
            })}
          </div>
          <div className={style.mobSwiper}>
                  
                  <Swiper
                           navigation={true}
                           pagination={{   
                             dynamicBullets: true,
                           }}
           
               modules={[Pagination,Navigation]}
               spaceBetween={20}
               className="mySwiper"
             >
             {modern.map((item, index) => {
                     return (
                       <SwiperSlide key={index}>
                       <Link to="/order/oil?petNo=1" className={style.homeCard} key={index}>
                         <img src={item.image} alt="theme" className={style.w100} />
                       </Link>
                       </SwiperSlide>
                     );
                   })}
             
             </Swiper>
             </div>
       
          <h1 className={style.mainHeader}>{label[lng].linePortrait}</h1>
            <div className={` ${style.collectionImage} ${style.lineCollection}`}>
            {line.map((item, index) => {
              return (
                <Link to="/order/line?petNo=1" className={style.homeCard} key={index}>
                  <img src={item.image} alt="theme" className={style.w100}   />
                </Link>
              );
            })}
          </div>
          <div className={style.mobSwiper}>
          <Swiper
                          pagination={{   
                            dynamicBullets: true,
                          }}
              navigation={true}
              modules={[Pagination,Navigation]}
              spaceBetween={20}
        className="mySwiper"
      >
      {line.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                <Link to="/order/line?petNo=1" className={style.homeCard} key={index}>
                  <img src={item.image} alt="theme" className={style.w100} />
                </Link>
                </SwiperSlide>
              );
            })} 
      
      </Swiper>
      </div>
            <h1 className={style.mainHeader} ref={portraitRef}>{label[lng].customPortrait}</h1>
            <div className={`grid4 ${style.collectionImage}`} >
            {portrait.slice(0, itemsToShow).map((item, index) => (
        <Link to="/order/portrait?petNo=1" className={`${style.homeCard} ${style.animateOpen}`} key={index}>
          <img src={item.image} alt="theme" className={style.w100} />
        </Link>
      ))}
          </div>
          {itemsToShow < portrait.length ? (
        <p className={style.more} onClick={handleViewMore}>
        {label[lng].peekMore}
        </p>
      ) : (
        <p className={style.more} onClick={handleViewLess}>
          {label[lng].peekLess}
        </p>
      )}
      <div className={style.mobSwiper}>
      <Swiper
                          pagination={{   
                            dynamicBullets: true,
                            
                          }}
              navigation={true}
              modules={[Pagination,Navigation]}
              spaceBetween={20}
        className="mySwiper"
      >
      {portrait.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                <Link to="/order/portrait?petNo=1" className={style.homeCard} key={index}>
                  <img src={item.image} alt="theme" className={style.w100} />
                </Link>
                </SwiperSlide>
              );
            })}
      
      </Swiper>
           </div>
          <Reverse/>
       
        </div>
      


      
        </div>
          <Slider/>
          
         
          </>
    )
}

export default Collection;