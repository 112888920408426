import { Link } from "react-router-dom";
import MainBanner from "../../components/banner/MainBanner";
import Products from "../../layout/products/Products";
import style from "./Home.module.css";
import HowItWorks from "../../layout/helpers/HowItWorks";
import FAQ from "../../layout/faq/Faq";
import { label } from "../../helpers/language";
import { useSelector } from "react-redux";
import Slider from "../../components/slider/slider";
import Card from "../../components/card/Card";
import NewsletterSignup from "../../components/newsletter/NewsLetter";
const Home = () => {
  const { lng } = useSelector((state) => state.global);

  const portrait = [
    {
      image: "/images/general/17.webp",
    },
    {
      image: "/images/general/5.webp",
    },

    {
      image: "/images/general/6.webp",
    },
    {
      image: "/images/general/7.webp",
    },
    {
      image: "/images/general/18.webp",
    },
    {
      image: "/images/general/16.webp",
    },
  ];
  const faqData = [
    {
      question: label[lng].questionOne,
      answer: label[lng].answerOne,
    },
    {
      question: label[lng].questionTwo,
      answer: label[lng].answerTwo,
    },
    {
      question: label[lng].questionThree,
      answer: label[lng].answerThree,
    },
    {
      question: label[lng].questionFour,
      answer: label[lng].asnwerFour,
    },
    {
      question: label[lng].questionFive,
      answer: label[lng].answerFive,
    },
  ];
  return (
    <>
      <MainBanner />
      <Products />
     <div className={style.viewAll}>
     <Link to="/shop" >{label[lng].viewAll}</Link>
     </div>
      <div className="container section">
        <div className="grid12">
          <div
            className={style.homeInfoCard}
            style={{ backgroundImage: "url(./images/general/Background.webp)" }}
          >
            <h1>{label[lng].furryFriend}</h1>
            <p>{label[lng].furryFriendDesc} </p>
            <Link to="/shop">{label[lng].cartShop}</Link>
          </div>
          <div className={style.homePortraits}>
            {portrait.map((item, index) => {
              return (
                <Link to="/shop" className={style.homeCard} key={index}>
                  <img src={item.image} alt="theme" className="w-100" />
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <div className="container section">
        <HowItWorks />
        <FAQ data={faqData} />
        {/*  <div className="section"> */}
        {/* <SocialMedia /> */}
        {/* </div>*/}
      </div>
      <NewsletterSignup />
      <Slider />
    </>
  );
};

export default Home;
