import React, { useEffect, useState } from "react";
import { client } from "../../helpers/config/api";
import Select from "react-select";
import { useSelector } from "react-redux";
import { get, map } from "lodash";

function CountrySelect(props) {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const { country } = useSelector((state) => state.global);

  useEffect(() => {
    getCountries();
  }, []);

  function getCountries() {
    client.get(`/countries`).then((response) => {
      const { data } = response;

      if (data.isSuccess) {
        setCountries(get(data, "data"));
        setLoading(false);
      }
    });
  }

  function selectCountry(values) {
    const discount = countries[values.value].hasDiscount;
    props.selectCountry({
      country: values.value,
      shippingType: discount ? "balkan" : "worldwide",
    });
  }

  if (loading) return <span>loading</span>;

  const COUNTRY_SELECT = map(countries, (value, key) => {
    return {
      label: key,
      value: key,
    };
  });

  return (
    <>
      <Select
        defaultValue={{
          label: country,
          value: country,
        }}
        onChange={(values) => {
          selectCountry(values);
        }}
        options={COUNTRY_SELECT}
      />
    </>
  );
}

export default CountrySelect;
