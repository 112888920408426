import React, { useState } from "react";
import { Link } from "react-router-dom";

import style from "./ShoppingCart.module.css";
import { useDispatch, useSelector } from "react-redux";
import { get, map, size } from "lodash";
import { priceFormatter } from "../../@boltbox/prices";
import { removeFromCart, closeCart } from "../../redux/slice";
import { label } from "../../helpers/language";

function ShoppingCart() {
  const { lng, openCart } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const { order, shippingType } = useSelector((state) => state.global);
  const { cart, shippingFee } = order;

  const price = cart.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.price[shippingType];
  }, 0);

  const hasProduct = size(cart) > 0;

  function removeItemFromCart(index) {
    dispatch(removeFromCart({ index }));
  }

  function closeCartHandler(value) {
    dispatch(closeCart(value));
  }

  return (
    <div className={style.shoppingCart}>
      <div className="cartIcon" onClick={() => closeCartHandler(!openCart)}>
        <img src="/images/general/cart.png" alt="cart" />
        <span>{size(cart)}</span>
      </div>
      {openCart && (
        <>
          <div
            className={style.overlay}
            onClick={() => closeCartHandler(false)}
          ></div>
          <div className={style.shoppingCartWrapper}>
            <div className={style.cartHeader}>
              <h1>{label[lng].cartHeading}</h1>

              <img
                onClick={() => closeCartHandler(false)}
                src="/images/close.png"
                alt="close"
              />
            </div>
            {hasProduct ? (
              <>
                <div className={style.cartBody}>
                  {cart.map((item, index) => {
                    return (
                      <div
                        className={style.cartItem}
                        key={`cart-item-${index}`}
                      >
                        <div className={style.imageWrapper}>
                          <img src={item.preview} alt="language" className={style[item.frame]} />
                        
                        </div>
                        <div>
                          <p>{item.product}</p>
                          <span>{item.type}</span>
                          <span>
                            Pet Name:
                            {item.data.map((value, index) => {
                              return index < item.data.length - 1
                                ? value.name + ", "
                                : value.name;
                            })}
                          </span>
                          <p>{get(item, `price.${shippingType}`)} &euro;</p>
                        </div>
                        <div
                          onClick={() => removeItemFromCart(index)}
                          className={style.deleteItem}
                        >
                          <img src="/images/general/trash.webp" alt="trash" />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={style.cartFooter}>
                  <div className={style.cartPrice}>
                    <div>
                      <span>Subtotal: </span>
                      {priceFormatter(price)}&euro;
                    </div>
                    <div>
                      <span>Shipping Fee: </span>
                      {priceFormatter(shippingFee)}&euro;
                    </div>
                    <div>
                      <span>Total: </span>
                      {priceFormatter(price + shippingFee)}&euro;
                    </div>
                    <p>
                      Our team is always available to assist you with any
                      questions or concerns.
                    </p>
                  </div>

                  {hasProduct && (
                    <Link
                      to="/checkout"
                      className="btn"
                      onClick={() => closeCartHandler(!openCart)}
                    >
                      Checkout
                    </Link>
                  )}
                </div>
              </>
            ) : (
              <div className={style.emptyCart}>
                <p>{label[lng].cartEmpty}</p>

                <Link to="/shop" onClick={() => closeCartHandler(!openCart)}>
                  {label[lng].cartShop}
                </Link>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ShoppingCart;
