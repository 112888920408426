import React from "react";
import { IoImageOutline } from "react-icons/io5";
import style from "./Attributes.module.css";
import { IoClose } from "react-icons/io5";
import { label } from "../../helpers/language";
import { useSelector } from "react-redux";

export default function ImageUpload({ images, setFieldValue, errors }) {
  const handleFileChanges = (e) => {
    const files = e.target.files;
    const allowedExtensions = ["image/jpeg", "image/jpg", "image/png"];
    let hasError = false;
    let fileArray = Array.from(files).slice(0, 2);

    // Validate each selected file
    for (let file of fileArray) {
      if (!allowedExtensions.includes(file.type)) {
        hasError = true;
        alert("Only JPEG and PNG files are allowed.");
        return;
      }
    }

    if (!hasError) {
      const imageDataPromises = Array.from(fileArray).map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(imageDataPromises).then((imageDataArray) => {
        setFieldValue(imageDataArray);
      });
    }
  };

  const removeImage = (imageIndex) => {
    const filteredFiles = images
      .filter((_, idx) => idx !== imageIndex)
      .map((item) => {
        const file = Array.from(
          document.querySelector('input[type="file"]').files
        ).find((f) => f.name === item.name);
        return file;
      });

    setFieldValue(filteredFiles);
  };

  // const getImagePreview = (value) => {
  //   return URL.createObjectURL(value);
  // };
  const { lng } = useSelector((state) => state.global);
  return (
    <>
      <div className={style.image}>
        <input
          type="file"
          onChange={handleFileChanges}
          multiple
          accept=".jpg, .jpeg, .png"
        />
        <IoImageOutline size={35} style={{ paddingRight: "10px" }} /> 
        <p>{label[lng].uploadImage}</p>
      </div>
      <div className="grid4">
        {images &&
          images.map((src, i) => (
            <div key={i} className={style.singleImage}>
              <img src={src} alt="Preview" width={100} />
              <IoClose
                onClick={() => removeImage(i)}
                className={style.closeIcon}
              />
            </div>
          ))}
      </div>
    </>
  );
}
