import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import style from "./Card.module.css";
import { Rating } from "react-simple-star-rating";
import { get, map } from "lodash";
import { label } from "../../helpers/language";
import { useSelector } from "react-redux";

const Card = forwardRef(({ data }, ref) => {
  const { lng, shippingType } = useSelector((state) => state.global);

  return (
    <div ref={ref}>
      <Link
      to={`/order/${data.name}?petNo=${get(data, "petNo", 1)}${map(
        get(data, "params", []),
        (value, key) => `${key}=${value}`
      ).join("&")}`}
      state={{ step: 2 }}
      className={style.cardWrapper}
    
    >
      <div className={style.cardImage} >
        <img src={`../images${data.image}`} alt={data.name} />
      </div>
      <div className={style.cardContent}>
        <div>
          <Rating size={20} initialValue={5} readonly={true} />
          <p>{label[lng][data.name]}</p>
          {/* Example of conditional rendering */}
          <p>{data.name === 'oil' || data.name === 'line' || data.name === 'portrait' ? <span>From</span> : ""} {get(data, `price[${shippingType}]`)}&euro;</p>
        </div>
        <p className={style.cardBtn}>{label[lng].orderNow}</p>
      </div>
      {data.ribon && (
        <p
          className={
            data.ribon === "TRENDING" ? style.trending : style.bestSeller
          }
        >
          {data.ribon}
        </p>
      )}
    </Link>
    </div>
  );
});

export default Card;
