import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper/modules";
import style from "./slider.module.css";
import { label } from "../../helpers/language";
import { useSelector } from "react-redux";

const Slider = () => {
  const { lng } = useSelector((state) => state.global);
  const slider = [
    {
      image: "/images/general/minnie.jpg",
    },
    {
      image: "/images/general/kio.jpg",
    },
    {
      image: "/images/general/makoo.jpg",
    },
    {
      image: "/images/general/teo.jpg",
    },
    {
      image: "/images/general/niko.jpg",
    },
    {
      image: "/images/general/hhugo.jpg",
    },
    {
      image: "/images/general/snow.jpg",
    },
    {
      image: "/images/general/brunaoil.jpg",
    },
    {
      image: "/images/general/yokoo.jpg",
    },
    {
      image: "/images/general/momoo.jpg",
    },
    {
      image: "/images/general/cat.jpg",
    },
    
    {
      image: "/images/general/miley.jpg",
    },
    
    {
      image: "/images/general/kam.jpeg",
    },
  ];
  return (
    <>
      <div className={`container ${style.sliderHeader}`}>
        <h2>
        {label[lng].happyClient} <span>{label[lng].motion}</span>
        </h2>
      </div>

      <Swiper
        watchSlidesProgress={true}
        autoplay={{
          delay: 0, // Set the autoplay delay to 5000ms (5 seconds)
        }}
        className="mySwiper pt-100"
        modules={[Autoplay]}
        centeredSlides={true}
        loop={true}
        speed={5000}
        breakpoints={{
          320: {
            slidesPerView: 1.2,
          },
          600: {
            slidesPerView: 1.2,
          },
          700: {
            slidesPerView: 2,
          },
          800: {
            slidesPerView: 2,
          },
          900: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 4.5,
          },
        }}
      >
        {slider.map((item, index) => {
          return (
            <SwiperSlide
              className={style.sliderPosition}
              key={`slider-${item.image}`}
            >
              <a href="https://www.instagram.com/boltbox_/" target="_blank"  rel="noreferrer">
                <img src={item.image} className="w-100" width="100%" alt="ig"/>
              </a>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default Slider;
