import React from "react";


import style from "./story.module.css";
import { useSelector } from "react-redux";
import { label } from "../../helpers/language";

const OurStory = () => {
  const { lng } = useSelector((state) => state.global);
  const image = [
    {
      image:"/images/general/img1.webp"
    },
    {
      image:"/images/general/img2.webp"
    },
    {
      image:"/images/general/paint.webp"
    },
  ]
    return (
      <>
        <div className={style.ourStory}>
        <img src={"/images/general/colorful.webp"} className="w-100" alt="banner" loading="lazy" /> 
            <div className="container pt-50">
             <h1>{label[lng].missionHeading}</h1>
          
            <div className={style.storyDisplay}>
              {image.map((item, index) => {
           
                return (
                  <img src={item.image} alt="error" key={index}/>
                )
              })}
              
            </div>
              <div className={style.boltLogo}>
              <img src={"/images/logo.svg"} alt="bolt" loading="lazy" />
              <p className="ptb-20">{label[lng].missionHistory}</p>
              </div>
            </div>
       </div>
 
      </>
    )


}

export default OurStory