import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lng: "eng",
  country: "",
  shippingType: "worldwide",
  products: [],
  openCart: false,
  order: {
    details: [],
    client: {},
    orderInfo: {},
    images: [],
    price: 0,
    shippingFee: 0,
    cart: [],
  },
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload.country;
      state.shippingType = action.payload.shippingType;
      if (action.payload.country === "Kosovo") state.order.shippingFee = 0;
      else state.order.shippingFee = 5;
    },
    setLanguage: (state, action) => {
      state.lng = action.payload;
    },
    setImages: (state, action) => {
      state.order.images = action.payload;
    },
    setDetailsInfo: (state, action) => {
      state.order.details = action.payload;
    },
    setClientInfo: (state, action) => {
      state.order.client = action.payload;
    },
    setOrderInfo: (state, action) => {
      state.order.orderInfo = action.payload;
    },
    setPrice: (state, action) => {
      state.order.price = parseFloat(action.payload).toFixed(2);
    },
    addToCart: (state, action) => {
      const newProduct = action.payload;
      state.openCart = true;
      state.order.cart.push(newProduct);
      state.order.price = state.order.price + newProduct.price;
    },
    removeFromCart: (state, action) => {
      const index = action.payload.index;
      const productToRemove = state.order.cart[index];
      if (productToRemove) {
        state.order.price -= productToRemove.price;
        state.order.cart.splice(index, 1);
      }
    },
    updateCartItem: (state, action) => {
      const index = state.order.cart.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        state.order.cart[index] = action.payload;
      }
    },
    closeCart: (state, action) => {
      state.openCart = action.payload;
    },
    resetGlobal: () => initialState,
  },
});

export const {
  setProducts,
  setLanguage,
  setDetailsInfo,
  setOrderInfo,
  setCountry,
  setClientInfo,
  setImages,
  resetGlobal,
  setPrice,
  addToCart,
  closeCart,
  removeFromCart,
  updateCartItem,
} = globalSlice.actions;

export default globalSlice.reducer;
