import Products from "../products/Products";

import Lottie from "react-lottie-player";
import loader from "../../helpers/lotties/loader.json";
import HeadCrumbs from "../../components/crumbs/HeadCrumbs";
import Reverse from "../../components/reverse/reverse";
import HowItWorks from "../helpers/HowItWorks";
import Slider from "../../components/slider/slider";

const OrderWrapper = ({ children, loading = false }) => {
  if (loading) {
    return (
      <div className="mainLoader">
        <Lottie
          loop
          animationData={loader}
          play
          className="lottie-container"
          style={{ width: 150, height: 150 }}
        />
        ;
      </div>
    );
  }

  return (
    <>
      <div className={`container`}>
        <HeadCrumbs title="Order" link="/order" />
        <div>{children}</div>
        <Products />
        <Reverse />
        <HowItWorks />
      </div>
      <Slider />
    </>
  );
};

export default OrderWrapper;
