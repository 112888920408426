import React from "react";
import style from "./contact.module.css";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { label } from "../../helpers/language";
import { useSelector } from "react-redux";

const Contact = () => {
  const { lng } = useSelector((state) => state.global);

  return (
    <>
      <div className={style.contact}>
        <img
          src="/images/general/paintphone.webp"
          className="w-100"
          alt="contact"
          loading="lazy"
        />
        <div className="container">
          <h1>{label[lng].contactHeading}</h1>
          <p>{label[lng].contactSub}</p>
          <div className={style.contactForm}>
            <Formik
              initialValues={{
                email: "",
                subject: "",
                description: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "Email address is required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                if (!values.subject) {
                  errors.subject = "Subject is required";
                }
                if (!values.description) {
                  errors.description = "Description is required";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                // You can handle form submission and data saving here
            
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="w-100">
                  <label htmlFor="email">{label[lng].contactEmail}</label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email address"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />

                  <label htmlFor="subject">{label[lng].contactSubject}</label>
                  <Field
                    type="text"
                    name="subject"
                    id="subject"
                    placeholder="Enter the subject"
                  />
                  <ErrorMessage
                    name="subject"
                    component="div"
                    className="error-message"
                  />

                  <label htmlFor="description">{label[lng].contactText}</label>
                  <Field
                    as="textarea"
                    name="description"
                    id="description"
                    style={{
                      maxWidth: "100%",
                      resize: "none",
                      minHeight: "200px",
                    }}
                    placeholder="Enter your message here"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="error-message"
                  />

         

                  <button type="submit" disabled={isSubmitting}>
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
            <div className={style.contactFaq}>
                    <label htmlFor="question">{label[lng].contactQuestion}</label>
                    <Link to="/faq" id="question">
                    {label[lng].contactAnswer}
                    </Link>
                  </div>    
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
