import Heading from "../../components/heading/Heading";
import Card from "../../components/card/Card";
import { useSelector } from "react-redux";
import { label } from "../../helpers/language";
import style from "./Products.module.css";
import { useRef } from "react";

const Products = () => {
  const { lng } = useSelector((state) => state.global);
  const { products } = useSelector((state) => state.global);

  const paint = useRef(null);
  const canvas = useRef(null);
  const line = useRef(null);
  const hoodie = useRef(null);
  const mug = useRef(null);
  const casee = useRef(null);
  const toteBlack = useRef(null);

  const refs = {
    paint,
    canvas,
    line,
    hoodie,
    mug,
    casee,
    toteBlack,
  };

  const scrollToSection = (ref) => {
    const scrollOptions = {
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    };
  
    // Calculate the offset to scroll 50 pixels above the target element
    const offset = ref.current.offsetTop - 120;
  
    window.scrollTo({
      top: offset,
      ...scrollOptions
    });
  };
  return (
    <div className="container section">
      <Heading text={label[lng].recProducts} customStyle={"main"} />
      <div className={style.goToProduct}>
        <p onClick={() => scrollToSection(paint)}>Modern Pet Portrait</p>
        <p onClick={() => scrollToSection(canvas)}>Custom Pet Portrait</p>
        <p onClick={() => scrollToSection(line)}>Line Pet Portrait</p>
        <p onClick={() => scrollToSection(hoodie)}>Hoodie Line Pet Portrait</p>
        <p onClick={() => scrollToSection(mug)}>Pet Portrait Mug</p>
        <p onClick={() => scrollToSection(casee)}>Pet Portrait Phone Cases</p>
        <p onClick={() => scrollToSection(toteBlack)}>Pet Portrait Tote Bag</p>
      </div>

      <div className="grid3Custom">
        {Object.entries(products).map(([key, value], index) => {
        
          const productRef = refs[key === 'case' ? 'casee' : key];

          return <Card data={value} ref={productRef} key={index} />;
        })}
      </div>
    </div>
  );
};

export default Products;
