import React from 'react';
import styles from './PopUpGuide.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';

const Popup = ({ add,imageSrc, onClose,imageMob,sliderMob,secondFrame ,firstTitle,secondTitle,secondMob}) => {
  return (
    <div className={`${styles.overlay}`} onClick={onClose}>
      <div className={`${styles[sliderMob]} ${styles.popupOverlay}`} onClick={(e) => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>×</button>
        
        <div className={styles.popupContent}>
          
         { firstTitle &&  <p className={styles.desPop}>{firstTitle}</p>}
         { !secondFrame &&  <p className={styles.mobPop} >{firstTitle}</p>}
          <img src={imageSrc} alt="Popup" className={`${styles.desPop} ${styles[add]}`} />
          { secondTitle &&  <p className={styles.desPop}>{secondTitle}</p>}
          {secondFrame &&       <img src={secondFrame}   alt="Popup" className={`${styles.desPop} `} /> }
          {!secondFrame && 
          <img src={imageMob} alt='Popup' className={styles.mobPop} />}
            

    {secondFrame &&     <Swiper

                           // navigation={true}
                           pagination={{   
                             dynamicBullets: true,
                           }}
               // navigation={true}
               modules={[Pagination]}
               spaceBetween={20}
               className={`${styles.mobPop} mySwiper`}
             >
          
          <SwiperSlide
      
             
      >
         { firstTitle &&  <p>{firstTitle}</p>}
          <img src={imageMob} className="w-100" width="100%" />
     
      </SwiperSlide>
      <SwiperSlide

       
>
{ secondTitle &&  <p>{secondTitle}</p>}
    <img src={secondMob} className="w-100" width="100%" />

</SwiperSlide>
             
             </Swiper>}
          
   
        </div>
      </div>
    </div>
  );
};

export default Popup;
