export const DEFAULT_MUG = {
  product: "MUG",
  theme: "oil",
  type: "oil",
  data: [{ name: "", images: [] }],
};

export const DEFAULT_HODDIE = {
  product: "HOODIE",
  color: "black",
  data: [{ name: "", images: [] }],
};

export const DEFAULT_BAG = {
  product: "BAG",
  color: "black",
  data: [{ name: "", images: [] }],
};

export const DEFAULT_LINE = {
  product: "LINE",
  frame: "noframe",
  size: "33x48",
  type: "canvas",
  data: [{ name: "", images: [] }],
};

export const DEFAULT_CASE = {
  product: "CASE",
  theme: "oil",
  type: "oil",
  cell: {
    value: "iphone15pro",
    label: "iPhone 15 Pro",
  },
  data: [{ name: "", images: [] }],
};

export const ToteBagConfig = (color = "black") => {
  return {
    product: "BAG",
    color: color,
    data: [{ name: "", images: [] }],
  };
};

export const CaseConfig = (type = "oil", theme = "oil") => {
  return {
    product: "CASE",
    theme: theme,
    type: type,
    cell: {
      value: "iphone15pro",
      label: "iPhone 15 Pro",
    },
    data: [{ name: "", images: [] }],
  };
};
export const HoodieConfig = (color = "black") => {
  return {
    product: "HOODIE",
    color: color,
    data: [{ name: "", images: [] }],
  };
};

export const MugConfig = (type = "oil", theme = "oil") => {
  return {
    product: "MUG",
    theme: theme,
    type: "oil",
    data: [{ name: "", images: [] }],
  };
};

export const CanvaConfig = (petNo = 1, theme="superhero") => {
  return {
    product: "CANVA",
    frame: "noframe",
    theme: theme,
    type: "canvas",
    size: "33x48",
    data: Array.from({ length: petNo }, (_, index) => ({
      name: "",
      images: [],
    })),
  };
};

export const OilConfig = (petNo = 1) => {
  return {
    product: "OIL",
    frame: "noframe",
    size: "33x48",
    type: "canvas",
    data: Array.from({ length: petNo }, (_, index) => ({
      name: "",
      images: [],
    })),
  };
};
