import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import style from "./Shop.module.css";
import { client } from "../../helpers/config/api";
import { useSelector } from "react-redux";
import Slider from "../../components/slider/slider";
import Lottie from "react-lottie-player";
import loader from "../../helpers/lotties/loader.json";
import { get, keys } from "lodash";
import { label } from "../../helpers/language";
import ToteBagShop from "./products/bag";
import HoodieShop from "./products/hoodie";
import MugShop from "./products/mug";
import CaseShop from "./products/case";
import CanvasShop from "./products/canvas";
import OilShop from "./products/paint";
import FAQ from "../../layout/faq/Faq";
import NewsletterSignup from "../../components/newsletter/NewsLetter";

const Shop = () => {
  const { lng } = useSelector((state) => state.global);
  const location = useLocation();
  const [products, setProducts] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeFilter, setActiveFilter] = useState("all");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filter = searchParams.get("filter");
    if (filter) {
      setActiveFilter(filter);
    }
    getAllProducts();
  }, [location.search]);

  function getAllProducts() {
    setLoading(true);
    client.get(`/products`).then((response) => {
      const { data } = response;
      if (data.isSuccess) {
        setProducts(data.data);
        setLoading(false);
      }
    });
  }

  const SHOP_DATA = {
    all: [
      <CanvasShop products={get(products, "canvas", [])} />,
      <OilShop products={get(products, "oil", [])} />,
      <MugShop products={get(products, "mug", [])} />,
      <HoodieShop products={get(products, "hoodie", [])} />,
      <CaseShop products={get(products, "case", [])} />,
      <ToteBagShop products={get(products, "bagData", [])} />,
    ],
    canvas: <CanvasShop products={get(products, "canvas", [])} />,
    oil: <OilShop products={get(products, "oil", [])} />,
    mug: <MugShop products={get(products, "mug", [])} />,
    hoodie: <HoodieShop products={get(products, "hoodie", [])} />,
    case: <CaseShop products={get(products, "case", [])} />,
    bagData: <ToteBagShop products={get(products, "bagData", [])} />,
  };

  const faqData = [
    {
      question: label[lng].questionOne,
      answer: label[lng].answerOne,
    },
    {
      question: label[lng].questionTwo,
      answer: label[lng].answerTwo,
    },
    {
      question: label[lng].questionThree,
      answer: label[lng].answerThree,
    },
    {
      question: label[lng].questionFour,
      answer: label[lng].asnwerFour,
    },
    {
      question: label[lng].questionFive,
      answer: label[lng].answerFive,
    },
  ];

  const handleFilterChange = (e) => {
    const filter = e.target.value;
    setActiveFilter(filter);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("filter", filter);
    window.history.pushState({}, "", `${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <>
      <div className="container">
        <div className={style.shopBanner}>
          <img src="/images/general/shop.webp" alt="shop" />
          <div className={style.overlay}></div>
          <div className={style.bannerDes}>
            <h2>{label[lng].shopAll}</h2>
            <p>{label[lng].shopAllDes}!</p>
          </div>
        </div>
        {loading ? (
          <div className="mainLoader">
            <Lottie
              loop
              animationData={loader}
              play
              className="lottie-container"
              style={{ width: 150, height: 150 }}
            />
          </div>
        ) : (
          <>
            <h2>{label[lng].explore}</h2>
            <select
              className={style.filterDropdown}
              value={activeFilter}
              onChange={handleFilterChange}
            >
              <option value="all">All Products</option>
              {Object.keys(products)
                .reverse() // Reverse the keys array to change rendering order
                .map((filterName, index) => {
                  if (filterName === "all") return null; // Skip 'all' category

                  let label = "";
                  switch (filterName) {
                    case "mug":
                      label = "Pet Portrait Mug";
                      break;
                    case "hoodie":
                      label = "Hoodie Line Pet Portrait";
                      break;
                    case "bagData":
                      label = "Pet Portrait Tote Bag";
                      break;
                    case "case":
                      label = "Pet Portrait Phone Cases";
                      break;
                    case "canvas":
                      label = "Custom Pet Portrait";
                      break;
                    case "oil":
                      label = "Modern Pet Portrait";
                      break;
                    default:
                      label = "";
                      break;
                  }

                  return (
                    <option key={index} value={filterName}>
                      {label}
                    </option>
                  );
                })}
            </select>

            {activeFilter === "all"
              ? SHOP_DATA["all"].map((Component, index) => (
                  <div key={index}>{Component}</div>
                ))
              : SHOP_DATA[activeFilter]}
          </>
        )}
      </div>

      <div className="container section">
        <FAQ data={faqData} />
      </div>
      <NewsletterSignup />
      <Slider />
    </>
  );
};

export default Shop;
