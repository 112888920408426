import { useSelector } from "react-redux";
import Heading from "../../components/heading/Heading";
import style from "./Demo.module.css";
import {AiOutlineCheck, AiOutlineClose} from "react-icons/ai";
import { label } from "../../helpers/language";
const Demo = () => {
  const { lng } = useSelector((state) => state.global);
  const guide = [
    {
      images: "/images/general/kam23.webp",
      title: "good",
      icon: "/images/tick.png",
      bad1:  label[lng].guideTickOne,
      bad2: label[lng].guideTickTwo,
      bad3: label[lng].guideTickThree,
    },
    {
      images: "/images/general/kam2.webp",
      title: "bad",
      icon: "/images/cancel.png",
      bad1: label[lng].guideNoOne,
      bad2:  label[lng].guideNoTwo,
      bad3: label[lng].guideNoThree,
    },
    {
      images: "/images/general/kam3.webp",
      title: "bad",
     
      icon: "/images/cancel.png",
      bad1: label[lng].guideBadTwo,
      bad2: label[lng].guideBadOne,
     
    },
  ];

  return (
   <div className="container">
     <div className={` ${style.guide}`}>
      <Heading text={ label[lng].guideHeading} customStyle={"main"} />
      <p className="pt-20">
      { label[lng].guideText} 
      </p>
      <div className={`grid3 ${style.guideWrapper}`}>
        {guide.map((item,index) => {
          return (
            <div className={style.guideCard} key={index}>
              <div>
                <img src={item.images} alt="guide" className="w-100" />
                <span className={style[item.title]}>{item.title === 'good' ? <AiOutlineCheck/> : <AiOutlineClose/>}</span>
              </div>
              <ul>
                <li>{item.bad1}</li>
                <li>{item.bad2}</li>
                <li>{item.bad3}</li>
              </ul>
            </div>
          );
        })}
      </div>
      <Heading text={label[lng].guideSure} customStyle={"main"} />
      <p className="ptb-20">
      {label[lng].guideSend}   <a href="mailto:info@bolt-box.com">info@bolt-box.com</a>  {label[lng].guideLook} 
      </p>
    </div>
   </div>
  );
};

export default Demo;
