import { get, map } from "lodash";
import { IMAGE_URL } from "../../../helpers/constants";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import style from "../Shop.module.css"
import { useSelector } from "react-redux";
import { label } from "../../../helpers/language";
const HoodieShop = ({ products }) => {
  const productImages = get(products, "assets.color.image", {});
  const { shippingType ,lng} = useSelector((state) => state.global);

  return (
    <div className={style.filterDis}>
      {map(productImages, (value, key) => (
        <Link to={`/order/hoodie?color=${key}`} key={`link-hoodie-${key}`}>
      
          
          <div className={style.cardImage}>
          <img loading="lazy" src={`${IMAGE_URL}${value}`} alt="Portait" />
          </div>
          <div className={style.cardContent}>
        <div>
          <Rating size={20} initialValue={5} readonly={true} />
          <p>
          Pet Line Art {key} Hoodie
         
          </p>
          <p>{get(products, `initialPrice[${shippingType}]`)}&euro;</p>
        </div>
        <p className={style.cardBtn}>{label[lng].orderNow}</p>
      </div>

      {key === 'black' || key === "white" || key === 'red' ?  <p
          className={
            style.bestSeller
          }
        >
          BEST SELLER
        </p> : ""}
        </Link>
      ))}
    </div>
  );
};

export default HoodieShop;
