// GENERAL

export const IMAGE_URL =  `https://www.bolt-box.com/api/`;



export const PET = ["1 ", "2", "3"];



export const THEME = [
  {
    url: "oil.png",
    name: "oil",
  },
  {
    url: "astro.png",
    name: "astro",
  },
  {
    url: "chef.png",
    name: "cook",
  },
  {
    url: "doctor.png",
    name: "doctor",
  },
  {
    url: "king.png",
    name: "king",
  },
  {
    url: "queen.png",
    name: "qween",
  },
  {
    url: "prof.png",
    name: "professor",
  },
  {
    url: "super.png",
    name: "superhero",
  },
];

// HOODIE
export const InitialHoodie = {
  petNo: 1,
  color: "black",
  size: "XS",
  price: 50,
};

export const HODDIE_SIZE = ["XS", "S", "M", "L", "XL", "XXL"];
export const HODDIE_COLORS = ["black", "blue", "orange", "gray"];

export const InitialPortrait = {
  petNo: 1,
  frame: "BLACK",
  theme: 0,
  price: 50,
  hasTheme: false,
};

export const type = ["canvas", "glass"];
export const FRAME = [
  {
    identifier: "BLACK",
    price: "10.00",
    name: "black",
  },
  {
    identifier: "WOOD",
    price: "10.00",
    name: "wood",
  },
  {
    identifier: "WHITE",
    price: "10.00",
    name: "white",
  },
  {
    identifier: "NOFRAME",
    price: "0.00",
    name: "noframe",
  },
];

export const size = [
  {
    price: "10.00",
    size: "33x48cm",
  },
  {
    price: "10.00",
    size: "33x48cm",
  },
  {
    price: "10.00",
    size: "30x21",
  },
  {
    price: "0.00",
    size: "50x70cm",
  },
];

//MUG

export const InitialMug = {
  petNo: 1,
  price: 50,
};
