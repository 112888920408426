import React, { useState, useRef, useEffect } from "react";
import PrivacyHeading from "../../components/privacyHeading/PrivacyHeading";
import Cart from "../../layout/cart/Cart";
import FAQ from "../../layout/faq/Faq";
import { label } from "../../helpers/language";

import { useSelector } from "react-redux";


const Faqs = () => {
  const { lng } = useSelector((state) => state.global);

    const faqData = [
        {
          question: label[lng].questionOne,
          answer:label[lng].answerOne,
        },
        {
          question: label[lng].questionTwo,
          answer:label[lng].answerTwo,
        },
        {
          question: label[lng].questionThree,
          answer:label[lng].answerThree,
          },
          {
            question: label[lng].questionFour,
            answer:label[lng].asnwerFour,
          },
          {
            question: label[lng].questionFive,
            answer:label[lng].answerFive,
          },
          {
            question: label[lng].questionSix,
            answer:label[lng].answerSix,
          },
          {
            question: label[lng].questionSeven,
            answer:label[lng].answerSeven,
          }, {
            question: label[lng].questionEight,
            answer:label[lng].answerEight,
          },
          {
            question: label[lng].questionNine,
            answer:label[lng].questionNine,
          },
          {
            question: label[lng].questionTen,
            answer:label[lng].answerTen,
            },
           
        // Add more FAQ items as needed
      ];


  return (

       
              <>
       
                 <PrivacyHeading title="FAQs" text="Last updated February 01, 2024"/>
    <Cart>
      <h1>{label[lng].faqHeading}</h1>
      <p>{label[lng].faqText} <a href="mailto:info@bolt-box.com">info@bolt-box.com</a>
</p>
<FAQ data={faqData}/>
    </Cart>
              </>
      
   
  );
};

export default Faqs;
