import React from "react";
import Heading from "../../components/heading/Heading";
import Cart from "../../layout/cart/Cart";
import PrivacyHeading from "../../components/privacyHeading/PrivacyHeading";
import { label } from "../../helpers/language";
import { useSelector } from "react-redux";

const Terms = () => {
  const { lng } = useSelector((state) => state.global);
  return (
    <>
      {/* <Heading text={"Terms & Conditions"} customStyle={"main"} /> */}
      <PrivacyHeading title={ label[lng].termsHeading}  />
      <Cart>
      <div className="privacy">
        <p className="pt-20">
          <b>{ label[lng].termsOverview} </b>
        </p>
        <p>
        { label[lng].termsOverviewDes}
        </p>

        <p>
        { label[lng].termsOverviewDesTwo}
        </p>
        <p>
        { label[lng].termsOverviewDesThree}
        </p>

        <p>
        { label[lng].termsOverviewDesFour}
        </p>
        <p className="pt-20">
          <b>   { label[lng].termsSection}</b>
        </p>
        <p>
        { label[lng].termsSectionDes}
        </p>
        <p>{label[lng].termsSection2}</p>
        <p>
        { label[lng].termsSection2Des}
        </p>

        <p className="pt-20">
          <b>
          { label[lng].termsSection3}
          </b>
        </p>
        <p>
        { label[lng].termsSection3Des}
        </p>

        <p className="pt-20">
          {" "}
          <b> { label[lng].termsSection4}</b>
        </p>
        <p>
        { label[lng].termsSection4Des}
        </p>
        <p className="pt-20">
          <b>{ label[lng].termsSection5}</b>
        </p>
        <p>
        { label[lng].termsSection5Des}
        </p>
        <p className="pt-20">
          <b>   { label[lng].termsSection6}</b>
        </p>
        <p>
        { label[lng].termsSection6Des}
        </p>
        <p className="pt-20">
          <b>  { label[lng].termsSection7}</b>
        </p>
        <p></p>

        <p>
        { label[lng].termsSection7Des}
        </p>
        <p className="pt-20">
          <b>    { label[lng].termsSection8}</b>
        </p>
        <p className="pb-20">
        { label[lng].termsSection8Des}
        </p>
        <p className="pt-20">
          <b>     { label[lng].termsSection9}</b>
        </p>
        <p>
        { label[lng].termsSection9Des}
        </p>
        <p className="pt-20">
          <b>    { label[lng].termsSection10}</b>
        </p>
        <p>
        { label[lng].termsSection10Des}
        </p>
        <p className="pt-20">
          <b>  { label[lng].termsSection11}</b>
        </p>
        <p>
        { label[lng].termsSection11Des}
        </p>
        <p className="pt-20">
          <b>  { label[lng].termsSection12}</b>
        </p>
        <p>
        { label[lng].termsSection12Des}
        </p>
        <p className="pt-20">
          <b>       { label[lng].termsSection13}</b>
        </p>
        <p>
        { label[lng].termsSection13Des}
        </p>
        <p className="pt-20">
          <b> { label[lng].termsSection14}</b>
        </p>
        <p>
        { label[lng].termsSection14Des}
        </p>
        <p className="pt-20">
          <b> { label[lng].termsSection15}</b>
        </p>
        <p>
        { label[lng].termsSection15Des}
        </p>
        <p className="pt-20">
          <b>{ label[lng].termsSection16}</b>
        </p>
        <p>
        { label[lng].termsSection16Des}
        </p>
        <p className="pt-20">
          <b>   { label[lng].termsSection17}</b>
        </p>
        <p>
        { label[lng].termsSection17Des}
        </p>
        <p className="pt-20">
          <b>   { label[lng].termsSection18}</b>
        </p>
        <p>
        { label[lng].termsSection18Des}
        </p>
        <p className="pt-20">
          <b>  { label[lng].termsSection19}</b>
        </p>
        <p>
        { label[lng].termsSection19Des}
        </p>
        <p className="pt-20">
          <b>  { label[lng].termsSection20}</b>
        </p>
        <p className="pb-50">
        { label[lng].termsSection20Des}
          <a href="mailto:info@bolt-box.com">info@bolt-box.com</a>.
        </p>
      </div>
      </Cart>
    </>
  );
};

export default Terms;
