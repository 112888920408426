import style from "./Checkout.module.css";
import "react-phone-number-input/style.css";
import { label } from "../../helpers/language";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useState } from "react";
import { useSelector } from "react-redux";
import {  get } from "lodash";
import {  priceFormatter } from "../../@boltbox/prices";

const CheckoutSummary = () => {
  const { order, lng, shippingType } = useSelector((state) => state.global);
  const { cart, shippingFee } = order;

  const [openMobileMenu, setMobileMenu] = useState(false);

  const price = cart.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.price[shippingType];
  }, 0);
 
  return (
    <div>
      <div className={style.checkoutSummaryMobile}>
        <p onClick={() => setMobileMenu(!openMobileMenu)}>
          {label[lng].orderDetails}{" "}
          {openMobileMenu ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}
        </p>
      </div>
      <div
        className={`${style.checkoutSummary} ${style.mobileSummary}`}
        style={{ display: openMobileMenu && "block" }}
      >
        <div className={style.checkoutItem}>
        {cart.map((item,index) => {
          return (
            <div className={style.cartItem} key={index}>
              <div className={style.imageWrapper}>
                <img src={item.preview} alt="preview" className={style[item.frame]} />
              </div>
              <div className={style.selectedDis}>
                <div>
                  <h3>{item.product}</h3>
                  <div className={style.selectedFrame}>
                    <span>{item.product}</span>/
                    <span>
                      Pet Name:
                      {item.data.map((value, index) => {
    return index < item.data.length - 1 ? value.name + ', ' : value.name;
})}
                    </span>
                  </div>
                </div>
                <p>{get(item, `price.${shippingType}`)} &euro;</p>
              </div>
            </div>
          );
        })}
        </div>
        <div className={style.checkoutPrice}>
        <div className={style.flexPrice}>
          <p>Subtotal</p>
          <span>{priceFormatter(price)} &euro;</span>
        </div>
        <div className={style.flexPrice}>
          <p> {label[lng].shipping}</p>
          <span>{priceFormatter(shippingFee)} &euro;</span>
        </div>
        <div className={`${style.flexPrice} ${style.totalPrice}`}>
          <p>Total</p>
          <span> {priceFormatter(price + shippingFee)} &euro;</span>
        </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSummary;
