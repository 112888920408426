import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import style from "../Order.module.css";
import { useDispatch, useSelector } from "react-redux";
import { client } from "../../../helpers/config/api";
import { Formik, Form, Field, FieldArray } from "formik";
import { label } from "../../../helpers/language";
import ImageUpload from "../../../components/order/ImageUpload";
import { Color } from "../../../components/order/Attributes";
import { get } from "lodash";
import { addToCart } from "../../../redux/slice";
import { ToastContainer } from "react-toastify";
import { DEFAULT_BAG, ToteBagConfig } from "../../../@boltbox/orderConfig";
import { BALKAN, WORLDWIDE, calculateBagPrice } from "../../../@boltbox/prices";
import { IMAGE_URL } from "../../../helpers/constants";
import { validationSchema } from "./validation";
import OrderWrapper from "../../../layout/order/OrderWrapper";
import { Rating } from "react-simple-star-rating";
import { useNavigate, useSearchParams } from "react-router-dom";

const Bag = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lng, shippingType } = useSelector((state) => state.global);
  const [loading, setLoading] = useState(true);
  const [bagConfiguration, setBagConfiguration] = useState(null);
  const [redirectToCheckout, setRedirectToCheckout] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getHoodieConfig();
  }, []);

  function getHoodieConfig() {
    client.get(`/product/bag`).then((response) => {
      const { data } = response;
      if (data.isSuccess) {
        setBagConfiguration(data.data);
        setLoading(false);
      }
    });
  }

  return (
    <OrderWrapper loading={loading}>
      <div className={` ${style.orderWrapper}`}>
        <div>
          <Formik
            initialValues={ToteBagConfig(searchParams.get("color") || "black")}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              const bagData = values;
              bagData.price = {
                [BALKAN]: calculateBagPrice(BALKAN, bagConfiguration, values),
                [WORLDWIDE]: calculateBagPrice(
                  WORLDWIDE,
                  bagConfiguration,
                  values
                ),
              };
              bagData.preview = `${IMAGE_URL}${get(
                bagConfiguration,
                `assets.color.image.${values.color}`
              )}`;
              dispatch(addToCart(bagData));

              resetForm();

              if (redirectToCheckout) {
                navigate("/checkout");
                setRedirectToCheckout(false);
              }
            }}
          >
            {({ setFieldValue, errors, values, touched, submitForm }) => (
              <Form>
                <ToastContainer />
                <div className={style.productInfoModel}>
                  <div>
                    <div className={`${style.imageWall} ${style.imageTote}`}>
                      <img
                        loading="lazy"
                        src={`${IMAGE_URL}${get(
                          bagConfiguration,
                          `assets.color.image.${values.color}`
                        )}`}
                        alt="Portait"
                      />
                    </div>
                  </div>
                  <div className={style.orderBody}>
                    <h2>{label[lng].orderBag}</h2>
                    <div className={style.subTotalPrice}>
                      <Rating size={20} initialValue={5} readonly={true} />
                      <h1>
                        {label[lng].price}:
                        <span>
                          {calculateBagPrice(
                            shippingType,
                            bagConfiguration,
                            values
                          )}
                          &euro;
                        </span>
                      </h1>
                    </div>
                    <FieldArray
                      name="data"
                      render={(arrayHelpers) => (
                        <div>
                          <p>{label[lng].animalDetails}</p>
                          {values &&
                            values.data.map((item, index) => (
                              <div key={index}>
                                <Field
                                  name={`data[${index}].name`}
                                  type="text"
                                  placeholder="Name"
                                  className={`${
                                    errors.data?.[index]?.name &&
                                    touched.data?.[index]?.name
                                      ? style.borderError
                                      : ""
                                  }`}
                                />
                                {errors.data?.[index]?.name &&
                                  touched.data?.[index]?.name && (
                                    <div className={style.error}>
                                      {errors.data[index].name}
                                    </div>
                                  )}
                                <ImageUpload
                                  index={index}
                                  images={item.images}
                                  setFieldValue={(values) => {
                                    setFieldValue(
                                      `data[${index}].images`,
                                      values
                                    );
                                  }}
                                />
                                {errors.data?.[index]?.images &&
                                  touched.data?.[index]?.images && (
                                    <div className={style.error}>
                                      {errors.data[index].images}
                                    </div>
                                  )}
                              </div>
                            ))}
                        </div>
                      )}
                    />
                    <Color
                      colors={get(bagConfiguration, "assets.color.value", [])}
                      active={values.color}
                      select={(color) => {
                        setSearchParams({ color: color });
                        setFieldValue("color", color);
                      }}
                    />
                    <div className={style.shopBtn}>
                      <button type={"submit"} className="btn">
                        {label[lng].addCart}
                      </button>
                      <button
                        className={style.shopColorBtn}
                        onClick={() => {
                          setRedirectToCheckout(true);
                          submitForm();
                        }}
                      >
                        {label[lng].buyNow}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </OrderWrapper>
  );
};

export default Bag;
