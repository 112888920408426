import Hamburger from "hamburger-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import style from "./Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RiArrowDropDownFill } from "react-icons/ri";
import { setLanguage } from "../../redux/slice";
import { label } from "../../helpers/language";
import ShoppingCart from "../../components/cart/ShoppingCart";
const Header = () => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);

  const [isOpen, setOpen] = useState(false);
  const { lng } = useSelector((state) => state.global);

  const language = {
    eng: { name: "English", img: "eng.png", label: "eng" },
    deu: { name: "Deutsch", img: "ger.png", label: "deu" },
  };

  const changeLanguage = (selectedLanguage) => {
    dispatch(setLanguage(selectedLanguage));
  };


  const products = [
    {
      title:"Modern Pet Portrait",
      image:"/images/all/miley.webp",
      ribbon:"Best Seller",
      link:"/shop?filter=oil"
    },
    {
      title:"Mug",
      image:"/images/all/mug.webp",
      ribbon:"Trending",
         link:"/shop?filter=mug"
    },
    {
      title:"Phone Case",
      image:"/images/all/1.webp",
      ribbon:"Trending",
        link:"/shop?filter=case"
    },
    {
      title:"Hoodie",
      image:"/images/all/duksi5.webp",
      ribbon:"Best Seller",
          link:"/shop?filter=hoodie"
    },
  
  ]

  const productsDes = [
    {
      title:"Modern Pet Portrait",
      image:"/images/all/miley.webp",
      ribbon:"Best Seller",
      link:"/shop?filter=oil"
    },
    {
      title:"Costum Pet Portrait",
      image:"/images/all/canvas.webp",
      ribbon:"Best Seller",
      link:"/shop?filter=canvas"
    },
    {
      title:"Line Pet Portrait",
      image:"/images/all/line.webp",
      ribbon:"New!",
      link:"/shop?filter=oil"
    },
    {
      title:"Pet Portrait Mug",
      image:"/images/all/mug.webp",
      ribbon:"Trending",
         link:"/shop?filter=mug"
    },
    {
      title:"Phone Case",
      image:"/images/all/1.webp",
      ribbon:"Trending",
        link:"/shop?filter=case"
    },
    {
      title:"Line Phone Case",
      image:"/images/all/7.webp",
      ribbon:"Trending",
        link:"/shop?filter=case"
    },
    {
      title:"Hoodie Line Pet Portrait",
      image:"/images/all/duksi5.webp",
      ribbon:"Best Seller",
          link:"/shop?filter=hoodie"
    },
    {
      title:"Line Pet Portrait",
      image:"/images/all/totblack.webp",
      ribbon:"New!",
      link:"/shop?filter=bagData"
    },
  
  ]
  return (
    <div className={style.header}>
      <div className={`container flex ${style.desktopHeader}`}>
        <Link to="/" className={style.logo} >
          <img src={"/images/logo.svg"} alt="logo" loading="lazy" />
        </Link>
        <ul>
          <li>
            <Link to="/theme" onClick={() => animateScroll.scrollToTop()}>
              {label[lng].monthlyTheme}{" "}
            </Link>
          </li>
          <li
      className={style.allProducts}
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <p>
        All Products <img src="/images/icons/ra.png" alt="All Products" className={`${style.headerArr} ${showDropdown && style.rotateArrow}`}/>
      </p>
      {showDropdown &&  <div className={style.dropdown}>
      <div className={style.mobProducts}>
        {productsDes.map(((item,index) =>{
          return(
            <Link
            key={index}
            to={item.link}
            onClick={() => {
              animateScroll.scrollToTop();
              setOpen(false);
              setShowDropdown(!showDropdown); // Toggle showDropdown
            }}
          >
            <img src={item.image} alt="mug"/>
            <p>{item.title}</p>

           {item.ribbon === "New!" ? <span className={style.newRib }>{item.ribbon}</span> :  <span className={item.ribbon === 'Trending' ? style.trending :""}>{item.ribbon}</span>}
          </Link>
          )
        }))}
       
       
      
      </div>
    </div>}
    </li>
          <li className={style.desBtn}>
            <Link
              to="/shop"
              onClick={() => animateScroll.scrollToTop()}
              className="btn"
            >
         {label[lng].cartShop}
            </Link>
          </li>
          <li className={style.language}>
            <div className={style.languageActive}>
              <img src={`/images/icons/${language[lng].img}`} alt="language" />
              {language[lng].name}
              <RiArrowDropDownFill />
            </div>
            <ul className={style.languageList}>
              {Object.values(language).map((i, index) => {
                return (
                  <li onClick={() => changeLanguage(i.label)} key={index}>
                    <img src={`/images/icons/${i.img}`} alt="language" />
                    {i.name}
                  </li>
                );
              })}
            </ul>
          </li>
          <li>
            <ShoppingCart />
          </li>
        </ul>
      </div>
      <div className={`${style.mobileNav} container`}>
        <div className={style.mobileDisplay}>
          <Link to="/" className={style.logo}   onClick={() => {
                  animateScroll.scrollToTop()
                  setOpen(false);
                }}>
            <img src="/images/logo.svg" alt="logo" loading="lazy" />
          </Link>
          <div className={style.mobileList}>
            <li className={style.language}>
              <div className={style.languageActive}>
                <img
                  src={`/images/icons/${language[lng].img}`}
                  alt="language"
                />
                <RiArrowDropDownFill />
              </div>
              <ul className={style.languageListMob}>
                {Object.values(language).map((i, index) => {
                  return (
                    <li onClick={() => changeLanguage(i.label)} key={index}>
                      <img src={`/images/icons/${i.img}`} alt="language" />
                      {i.name}
                    </li>
                  );
                })}
              </ul>
            </li>
            <li>
              <ShoppingCart />
            </li>
            <div className={style.mobileHeaderData}>
              <div className={style.hamburgerMenu}>
                <Hamburger toggled={isOpen} toggle={setOpen} size={23} />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${style.mobileMenuContainer} ${isOpen && style.opened}`}
        >
          <div className={style.mobProducts}>
            {products.map((item,index) =>{
              return(
                <Link
                key={index}
                to={item.link}
                onClick={() => {
                  animateScroll.scrollToTop()
                  setOpen(false);
                }}
              >
                <img src={item.image} alt="mug"/>
                <p>{item.title}</p>
                <span className={item.ribbon === 'Trending' ? style.trending :""}>{item.ribbon}</span>
              </Link>
              )
            })}
          
          </div>
          <ul className={`w-100 ${style.mobileArrow}`}>
            <li>
              <Link
                to="/theme"
                onClick={() => {
                  animateScroll.scrollToTop()
                  setOpen(false);
                }}
              >
                Monthly Themes
                <img src="/images/icons/ra.png"/>
              </Link>
            </li>
            <li>
              <Link
                to="/OurStory"
                onClick={() => {
                  animateScroll.scrollToTop()
                  setOpen(false);
                }}
              >
                Our Story
                <img src="/images/icons/ra.png"/>
              </Link>
            </li>
            <li>
              <Link
                to="/contact-us"
                onClick={() => {
                  animateScroll.scrollToTop()
                  setOpen(false);
                }}
              >
                Contact Us
                <img src="/images/icons/ra.png"/>
              </Link>
            </li>
            <li>
              <Link
                to="/demo"
                onClick={() => {
                  animateScroll.scrollToTop()
                  setOpen(false);
                }}
              >
                Photo Guide
                <img src="/images/icons/ra.png"/>
              </Link>
            </li>
            <li className={style.mobileBtnList}>
              <Link
                to="/shop"
             
                onClick={() => {
                  animateScroll.scrollToTop()
                  setOpen(false);
                }}
                className={`btn ${style.mobileBtn}`}
              >
      {label[lng].cartShop}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
