import React from "react";
import PrivacyHeading from "../../components/privacyHeading/PrivacyHeading";
import Cart from "../../layout/cart/Cart";
import { label } from "../../helpers/language";
import { useSelector } from "react-redux";

const Privacy = () => {
  const { lng } = useSelector((state) => state.global);
  return (
    <>
      {/* <Heading text={"Privacy Policy"} customStyle={"main"} /> */}
      <PrivacyHeading title={label[lng].policyHeading} text="" />
      <Cart>
      <div className="privacy">
        <p>
        {label[lng].policyFirstText}
        </p>
        <p className="pt-20">
          <b>   {label[lng].policyPersonalHeading}</b>
        </p>
        <p>
        {label[lng].policyInfoDevice}
        </p>
        <p className="pt-20">
        {label[lng].policyFollowingheading}
        </p>
        <p>
        {label[lng].policyCookies}
        <a href="https://www.allaboutcookies.org" target="_blank"  rel="noreferrer">https://www.allaboutcookies.org.</a>
          
        </p>
        <p>
        {label[lng].policyLogs}
        </p>
        <p>
        {label[lng].policyBeacons}
        </p>
        <p className="pt-50 pb-50">
        {label[lng].policyOrderInfo}
        </p>
        <p>
        {label[lng].policyTalk}
        </p>
        <p className="pt-20">
          <b>  {label[lng].policyUse}</b>
        </p>
        <p>
        {label[lng].policyUseDes}
        </p>
        <p>{label[lng].policyCommunicate}</p>
       
        <p>
        {label[lng].policyPreference}
        </p>
        <p>     {label[lng].policyAds}</p>
        <p className="pt-20">
        {label[lng].policyAdsDes}
        </p>
        <p className="pt-20">
          <b> {label[lng].policySharing}</b>
        </p>
        <p>
        {label[lng].policySharingDes}
        </p>
      
      
        <p className="pt-20">
          <b>   {label[lng].policyTrack}</b>
        </p>
        <p>
        {label[lng].policyTrackDes}
        </p>
        <p className="pt-20">
          <b> {label[lng].policyRights}</b>
        </p>
        <p>
        {label[lng].policyRightsDes}
        </p>
        <p className="pt-20">
          <b> {label[lng].policyData}</b>
        </p>
        <p>
        {label[lng].policyDataDes}
        </p>
        <p className="pt-20">
          <b> {label[lng].policyChanges}</b>
        </p>
        <p>
        {label[lng].policyChangesDes}
        </p>
        <p className="pt-20">
          <b>      {label[lng].policyContact}</b>
        </p>
        <p className="pb-20">
        {label[lng].policyContactDes} <a href="mailto:info@bolt-box.com">info@bolt-box.com</a>
        </p>
      </div>
      </Cart >
    </>
    
  );
};

export default Privacy;
