import { useEffect, useState } from "react";
import style from "../Order.module.css";
import { useDispatch, useSelector } from "react-redux";
import { client } from "../../../helpers/config/api";
import { Formik, Form, Field, FieldArray } from "formik";
import { label } from "../../../helpers/language";
import ImageUpload from "../../../components/order/ImageUpload";
import {
  PetNo,
  PortraitType,
  Theme,
} from "../../../components/order/Attributes";
import OrderWrapper from "../../../layout/order/OrderWrapper";
import { get } from "lodash";
import { addToCart } from "../../../redux/slice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IMAGE_URL } from "../../../helpers/constants";
import { calculateMugPrice, BALKAN, WORLDWIDE } from "../../../@boltbox/prices";
import { MugConfig } from "../../../@boltbox/orderConfig";
import { validationSchema } from "./validation";
import { Rating } from "react-simple-star-rating";
import { useNavigate, useSearchParams } from "react-router-dom";
const Mug = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { lng, shippingType } = useSelector((state) => state.global);
  const [loading, setLoading] = useState(true);
  const [mugConfiguration, setMugConfiguration] = useState(null);
  const [redirectToCheckout, setRedirectToCheckout] = useState(false);

  useEffect(() => {
    getMugConfiguration();
  }, []);

  function getMugConfiguration() {
    client.get(`/product/mug`).then((response) => {
      const { data } = response;
      if (data.isSuccess) {
        setMugConfiguration(data.data);
        setLoading(false);
      }
    });
  }

  function submitToCart(values, resetForm) {
    const mugData = values;
    mugData.price = {
      [BALKAN]: calculateMugPrice(BALKAN, mugConfiguration, values),
      [WORLDWIDE]: calculateMugPrice(WORLDWIDE, mugConfiguration, values),
    };
    mugData.preview =
      IMAGE_URL + get(mugConfiguration, `assets.theme.image.${values.theme}`);
    dispatch(addToCart(mugData));
    resetForm();
    if (redirectToCheckout) {
      navigate("/checkout");
      setRedirectToCheckout(false);
    }
  }

  const { petNo } = get(mugConfiguration, "assets", {});
  const allowedPetNo = get(petNo, "value", 1);

  return (
    <OrderWrapper loading={loading}>
      <div className={` ${style.orderWrapper}`}>
        <div>
          <Formik
            initialValues={MugConfig(
              searchParams.get("type") || "oil",
              searchParams.get("theme") || "oil"
            )}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              submitToCart(values, resetForm);
            }}
          >
            {({ submitForm, setFieldValue, errors, values, touched }) => (
              <Form>
                <ToastContainer />
                <div className={style.productInfoModel}>
                  <div>
                    <div className={`${style.imageWall} ${style.imageMug}`}>
                      <img
                        loading="lazy"
                        src={`${IMAGE_URL}${
                          values.type === "oil"
                            ? get(
                                mugConfiguration,
                                `assets.theme.image.${values.theme}`
                              )
                            : get(
                                mugConfiguration,
                                `assets.type.image.${values.type}`
                              )
                        }`}
                        alt="Portait"
                      />
                    </div>
                  </div>
                  <div className={style.orderBody}>
                    <h2>{label[lng].orderCup}</h2>
                    <div className={style.subTotalPrice}>
                      <Rating size={20} initialValue={5} readonly={true} />
                      <h1>
                        {label[lng].price}:
                        <span>
                          {" "}
                          {calculateMugPrice(
                            shippingType,
                            mugConfiguration,
                            values
                          )}{" "}
                          &euro;
                        </span>
                      </h1>
                    </div>
                    <PetNo
                      allowedPetNo={allowedPetNo}
                      order={values}
                      lng={lng}
                      setOrder={(number) => {
                        const newData = Array.from({ length: number }, () => ({
                          name: "",
                          images: [],
                        }));
                        setFieldValue("data", newData);
                      }}
                      onlyNumbers={true}
                    />
                    <FieldArray
                      name="data"
                      render={(arrayHelpers) => (
                        <div>
                          <p>{label[lng].animalDetails}</p>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(2,1fr)",
                              gap: "10px",
                            }}
                          >
                            {values &&
                              values.data.map((item, index) => (
                                <div key={index}>
                                  <Field
                                    name={`data[${index}].name`}
                                    type="text"
                                    placeholder="Name"
                                    style={{ width: "100%" }}
                                    className={`${
                                      errors.data?.[index]?.name &&
                                      touched.data?.[index]?.name
                                        ? style.borderError
                                        : ""
                                    }`}
                                  />
                                  {errors.data?.[index]?.name &&
                                    touched.data?.[index]?.name && (
                                      <div className={style.error}>
                                        {errors.data[index].name}
                                      </div>
                                    )}
                                  <ImageUpload
                                    index={index}
                                    images={item.images}
                                    setFieldValue={(values) => {
                                      setFieldValue(
                                        `data[${index}].images`,
                                        values
                                      );
                                    }}
                                  />
                                  {errors.data?.[index]?.images &&
                                    touched.data?.[index]?.images && (
                                      <div className={style.error}>
                                        {errors.data[index].images}
                                      </div>
                                    )}
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    />
                    <PortraitType
                      types={get(mugConfiguration, "assets.type.value", [])}
                      images={get(mugConfiguration, "assets.type.image", [])}
                      active={values.type}
                      select={(type) => {
                        setFieldValue("type", type);
                        if (type === "line") {
                          setSearchParams({ theme: type });
                        }
                      }}
                      lng={lng}
                    />
                    {values.type !== "line" && (
                      <Theme
                        active={values.theme}
                        themes={get(mugConfiguration, "assets.theme.value", [])}
                        select={(value) => {
                          setSearchParams({ theme: value });
                          setFieldValue("theme", value);
                        }}
                        lng={lng}
                      />
                    )}
                    <div className={style.shopBtn}>
                      <button type={"submit"} className="btn">
                        {label[lng].addCart}
                      </button>
                      <button
                        className={style.shopColorBtn}
                        onClick={() => {
                          setRedirectToCheckout(true);
                          submitForm();
                        }}
                      >
                        {label[lng].buyNow}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </OrderWrapper>
  );
};

export default Mug;
