import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  type: Yup.string()
    .required("Type is required")
    .oneOf(["oil", "line"], "Invalid type specified"),
  data: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .min(2, "Name must be at least 2 characters long"),
      images: Yup.array()
        .min(1, "At least one image is required")
        .max(2, "You can only upload up to 2 images"),
    })
  ),
});
