import { get } from "lodash";
import { IMAGE_URL } from "../../../helpers/constants";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import style from "../Shop.module.css"
import Card from "../../../components/card/Card";
import { useSelector } from "react-redux";
import { label } from "../../../helpers/language";
const MugShop = ({ products }) => {
  const productImages = get(products, "assets.theme.image", {});
  const themeValues = get(products, "assets.theme.value", {});
  const { shippingType ,lng} = useSelector((state) => state.global);

  return (
    <div className={`${style.filterDis} ${style.mugPad}`}>
      {Object.entries(productImages).map(([key, value], index) => (

        <Link
          to={`/order/mug?theme=${themeValues[index]}`}
          key={`link-mug-${key}`}
        >
          <div className={style.cardImage}>
          <img loading="lazy" src={`${IMAGE_URL}${value}`} alt="Portait" />
          </div>
          <div className={style.cardContent}>
        <div>
          <Rating size={20} initialValue={5} readonly={true} />
          <p>the {key}</p>
          <p>{get(products, `initialPrice[${shippingType}]`)}&euro;</p>
        </div>
        <p className={style.cardBtn}>{label[lng].orderNow}</p>
      
       
      </div>
      {key === 'oil' || key === 'cosmic' || key === 'bossy' || key === 'superhero' ?  <p
          className={
            style.bestSeller
          }
        >
          BEST SELLER
        </p> : ""}
        </Link>
      ))}
    </div>
  );
};

export default MugShop;
