import { useEffect, useState } from "react";
import style from "../Order.module.css";
import { useDispatch, useSelector } from "react-redux";
import { client } from "../../../helpers/config/api";
import { Formik, Form, Field, FieldArray } from "formik";
import { label } from "../../../helpers/language";
import ImageUpload from "../../../components/order/ImageUpload";
import { get, size } from "lodash";
import { addToCart } from "../../../redux/slice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrderWrapper from "../OrderWrapper";
import {
  Frame,
  PetNo,
  PortraitTypes,
  Size,
  Theme,
} from "../../../components/order/Attributes";
import { validationSchema } from "./validation";
import { CanvaConfig } from "../../../@boltbox/orderConfig";
import {
  BALKAN,
  WORLDWIDE,
  calculateCanvaPrice,
} from "../../../@boltbox/prices";
import { IMAGE_URL } from "../../../helpers/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";

const Portrait = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const { shippingType, lng } = useSelector((state) => state.global);
  const [loading, setLoading] = useState(true);
  const [canvaConfiguration, setCanvaConfiguration] = useState(null);
  const [redirectToCheckout, setRedirectToCheckout] = useState(false);

  useEffect(() => {
    getCanvaConfig();
  }, []);

  function getCanvaConfig() {
    client.get(`/product/canva`).then((response) => {
      const { data } = response;
      if (data.isSuccess) {
        setCanvaConfiguration(data.data);
        setLoading(false);
      }
    });
  }
  function getImage(values) {
    const petNo = size(get(values, "data", []));
    const theme = get(values, "theme", "defaultTheme");
    const image = get(canvaConfiguration, `assets.petNo.image`, "");

    const imagePathWithSize = image[petNo]?.replace("{{theme}}", theme);

    return `${IMAGE_URL}${imagePathWithSize}`;
  }
  const { petNo } = get(canvaConfiguration, "assets", {});

  const allowedPetNo = get(petNo, "value", 1);
  const framePricing = get(canvaConfiguration, "assets.frame.price", []);

  return (
    <OrderWrapper loading={loading}>
      <Formik
        initialValues={CanvaConfig(
          searchParams.get("petNo") || 1,
          searchParams.get("theme") || "superhero"
        )}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          const canvaData = values;
          canvaData.preview = getImage(values);

          canvaData.price = {
            [BALKAN]: calculateCanvaPrice(BALKAN, canvaConfiguration, values),
            [WORLDWIDE]: calculateCanvaPrice(
              WORLDWIDE,
              canvaConfiguration,
              values
            ),
          };
          dispatch(addToCart(canvaData));
          resetForm();
          if (redirectToCheckout) {
            navigate("/checkout");
            setRedirectToCheckout(false);
          }
        }}
      >
        {({ submitForm, setFieldValue, errors, values, touched }) => (
          <Form>
            <ToastContainer />
            <div className={style.productInfoModel}>
              <div>
                <div className={style.imageWall}>
                  <img
                    loading="lazy"
                    src={getImage(values)}
                    className={style[get(values, "frame")]}
                    alt="Portait"
                  />
                </div>
              </div>
              <div className={style.orderBody}>
                <h2>{label[lng].orderPortrait}</h2>
                <div className={style.subTotalPrice}>
                  <Rating size={20} initialValue={5} readonly={true} />
                  <h1>
                    {label[lng].price}:
                    <span>
                      {" "}
                      {calculateCanvaPrice(
                        shippingType,
                        canvaConfiguration,
                        values
                      )}{" "}
                      &euro;
                    </span>
                  </h1>
                </div>
                <div>
                  <div>
                    <Size
                      size={get(canvaConfiguration, "assets.size.value", [])}
                      active={values.size}
                      select={(size) => {
                        setFieldValue("size", size);
                      }}
                      lng={lng}
                    />
                    {errors.size && touched.size && (
                      <div className={style.error}>{errors.size}</div>
                    )}
                  </div>
                </div>
                <PetNo
                  allowedPetNo={allowedPetNo}
                  order={values}
                  lng={lng}
                  setOrder={(number) => {
                    setSearchParams({ petNo: number });
                    const newData = Array.from({ length: number }, () => ({
                      name: "",
                      images: [],
                    }));
                    setFieldValue("data", newData);
                  }}
                  onlyNumbers={true}
                />
                <FieldArray
                  name="data"
                  render={(arrayHelpers) => (
                    <div>
                      <p>{label[lng].animalDetails}</p>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2,1fr)",
                          gap: "10px",
                        }}
                      >
                        {values &&
                          values.data.map((item, index) => (
                            <div key={index}>
                              <Field
                                name={`data[${index}].name`}
                                type="text"
                                placeholder="Name"
                                style={{ width: "100%" }}
                                className={`${
                                  errors.data?.[index]?.name &&
                                  touched.data?.[index]?.name
                                    ? style.borderError
                                    : ""
                                }`}
                              />
                              {errors.data?.[index]?.name &&
                                touched.data?.[index]?.name && (
                                  <div className={style.error}>
                                    {errors.data[index].name}
                                  </div>
                                )}
                              <ImageUpload
                                index={index}
                                images={item.images}
                                setFieldValue={(values) => {
                                  setFieldValue(
                                    `data[${index}].images`,
                                    values
                                  );
                                }}
                              />
                              {errors.data?.[index]?.images &&
                                touched.data?.[index]?.images && (
                                  <div className={style.error}>
                                    {errors.data[index].images}
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                />

                <PortraitTypes
                  types={get(canvaConfiguration, "assets.type.value", [])}
                  select={(value) => {
                    if (
                      value === "glass" &&
                      (size(values.frame) === 0 || values.frame === "noframe")
                    ) {
                      setFieldValue("frame", "wood");
                    } else {
                      setFieldValue("frame", "noframe");
                    }

                    setFieldValue("type", value);
                  }}
                  active={values.type}
                  lng={lng}
                />
                <Frame
                  active={values.frame}
                  select={(value) => {
                    if (value === values.frame) {
                      if (values.type === "glass") {
                        toast.warning(
                          "Glass Type must have one of the frames!"
                        );
                      }
                    } else {
                      setFieldValue("frame", value);
                    }
                  }}
                  frames={get(canvaConfiguration, "assets.frame.value", [])}
                  price={framePricing[shippingType]}
                  images={get(canvaConfiguration, "assets.frame.image", [])}
                  lng={lng}
                  showPrice={values.type !== "glass"}
                />
                <div className="borderTop">
                  <Theme
                    active={values.theme}
                    themes={get(canvaConfiguration, "assets.theme.value", [])}
                    select={(value) => {
                      setFieldValue("theme", value);
                      setSearchParams({ theme: value });
                    }}
                    lng={lng}
                  />
                </div>

                <div className={style.shopBtn}>
                  <button type={"submit"} className="btn">
                    {label[lng].addCart}
                  </button>
                  <button
                    className={style.shopColorBtn}
                    onClick={() => {
                      setRedirectToCheckout(true);
                      submitForm();
                    }}
                  >
                    {label[lng].buyNow}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </OrderWrapper>
  );
};

export default Portrait;
