import { get, size, sumBy } from "lodash";

// const shipping = 
export const BALKAN = 'balkan';
export const WORLDWIDE = 'worldwide';

// export const shipping = localStorage.getItem('shippingType') || 'worldwide';

export function calculateMugPrice(shipping, configuration, selectedValues) {
  const prices = [
    get(configuration, `initialPrice[${shipping}]`, 0),
    get(configuration, `assets.petNo.price[${shipping}]${size(selectedValues.data)}`, 0),
    get(configuration, `assets.type.price[${shipping}].${selectedValues.type}`, 0),
  ];
  return sumPrices(prices);
}


export function calculateBagPrice(shipping, configuration, selectedValues) {

  const prices = [
    get(configuration, `initialPrice[${shipping}]`, 0),
    get(configuration, `assets.petNo.price[${shipping}]${size(selectedValues.data)}`, 0),
  ];
  return sumPrices(prices);
}

export function calculateCasePrice(shipping, configuration, selectedValues) {
  const prices = [
    get(configuration, `initialPrice[${shipping}]`, 0),
    get(configuration, `assets.petNo.price[${shipping}]${size(selectedValues.data)}`, 0),
    get(configuration, `assets.type.price[${shipping}].${selectedValues.type}`, 0),
    get(configuration, `assets.theme.price[${shipping}].${selectedValues.theme}`, 0),
  ];
  return sumPrices(prices);
}

export function calculateHoodiePrice(shipping, configuration, selectedValues) {
  const prices = [get(configuration, `initialPrice[${shipping}]`, 0)];
  return sumPrices(prices);
}

export function calculateCanvaPrice(shipping, configuration, selectedValues) {
  const prices = [
    get(configuration, `initialPrice[${shipping}]`, 0),
    get(configuration, `assets.petNo.price[${shipping}]${size(selectedValues.data)}`, 0),
    get(configuration, `assets.type.price[${shipping}].${selectedValues.type}`, 0),
    get(configuration, `assets.frame.price[${shipping}].${selectedValues.frame}`, 0),
    get(configuration, `assets.size.price[${shipping}].${selectedValues.size}`, 0),
  ];
  return sumPrices(prices);
}

export function calculateOilPrice(shipping, configuration, selectedValues) {

  const prices = [
    get(configuration, `initialPrice[${shipping}]`, 0),
    get(configuration, `assets.petNo.price[${shipping}]${size(selectedValues.data)}`, 0),
    get(configuration, `assets.frame.price[${shipping}].${selectedValues.frame}`, 0),
    get(configuration, `assets.size.price[${shipping}].${selectedValues.size}`, 0),
    get(configuration, `assets.type.price[${shipping}].${selectedValues.type}`, 0),

  ];

  return sumPrices(prices);
}

export function calculateLinePrice(shipping, configuration, selectedValues) {
  const prices = [
    get(configuration, `initialPrice[${shipping}]`, 0),
    get(configuration, `assets.petNo.price[${shipping}]${size(selectedValues.data)}`, 0),
    get(configuration, `assets.frame.price[${shipping}].${selectedValues.frame}`, 0),
    get(configuration, `assets.size.price[${shipping}].${selectedValues.size}`, 0),
    get(configuration, `assets.type.price[${shipping}].${selectedValues.type}`, 0),
  ];

  return sumPrices(prices);
}

export function priceFormatter(price) {
  // return parseFloat(price.toFixed(2));
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price);
}

function sumPrices(prices) {
  
  return parseFloat(sumBy(prices).toFixed(2));
}


