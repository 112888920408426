import React, { useState } from "react";
import { label } from "../../helpers/language";
import style from "./Attributes.module.css";
import { IMAGE_URL } from "../../helpers/constants";
import { size, get } from "lodash";
import Popup from "../popUpGuide/PopUpGuide";

import { Link, useLocation } from "react-router-dom";

export function PetNo({
  allowedPetNo,
  order,
  setOrder,
  lng,
  onlyNumbers = false,
}) {
  return (
    <div className="borderTop">
      <div className={style.sizeGuide}>
      <p className={style.attributesHeading}>{label[lng].objectNo}</p>
        <Link to="/demo" target="_blank"   rel="noreferrer">{label[lng].photoGuide}</Link>
      </div>
  
      
      <div className={style.petSelection}>
        {[...Array(allowedPetNo).keys()].map((index) =>
          onlyNumbers ? (
            <div
              key={`pet-no-${index}`}
              onClick={() => setOrder(index + 1)}
              className={`${style.petNoSelect} ${
                size(order.data) === index + 1 && style.active
              }`}
            >
              {index + 1}
            </div>
          ) : (
            <div
              className={`${style.assetSingle} ${
                size(order.data) === index + 1 && style.active
              }`}
              key={`frame-${index}`}
            >
              <img
                src={`/images/theme/${index + 1}.png`}
                alt={`theme`}
                onClick={() => setOrder(index + 1)}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
}

export function Color({ colors, active, select, lng }) {
  return (
    <div className="borderTop">
      <div className={style.colorDis}>
        {Object.keys(colors).map((key) => (
          <div
       
            onClick={() => {
              select(key);
              window.scrollTo(0, 0); // Scrolls to the top without smooth animation
            }}
            className={`${style.color} ${active === key && style.activeColor}`}
            style={{ backgroundColor: colors[key] }}
            key={`color-${key}`}
          ></div>
        ))}
      </div>
    </div>
  );
}

export function Size({ size, select, active, lng }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const location = useLocation();
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="borderTop">
      <div className={style.sizeGuide}>
        <p className={style.attributesHeading}>{label[lng].size}</p>
        {location.pathname.includes("/hoodie") ? " " : 
        <span onClick={handleOpenPopup}>{label[lng].sizeGuide}</span>}
      </div>
      <div className={`flex ${style.sizeWrap}`} style={{ gap: 10 }}>
        {size.map((size, index) => {
            //  console.log("INDEX",size)
          return (
            <div
              className={`${style.size} ${active === size && style.active}`}
              key={`size-${index}`}
              onClick={() => select(size)}
            >
              {size}
           
             
               { size === '33x48'  && <span>POPULAR</span>
             }
            
            </div>
          );
        })}

        {isPopupOpen && (
          <Popup
            imageSrc="/images/general/size.webp"
            imageMob="/images/general/dd.webp"
            onClose={handleClosePopup}
          />
        )}
      </div>
    </div>
  );
}

export function Frame({
  frames,
  active,
  select,
  images = [],
  price,
  showPrice = true,
  lng
  
}) {
  return (
    <div>
      <p className={style.attributesHeading}>{label[lng].chooseFrame}</p>
      <div className={style.frameDis}>
        {frames.map((item, index) => {
          return (
            !(!showPrice && index + 1 === size(frames)) && (
              <div key={`frame-${index}`}>
                <img
                  key={index}
                  onClick={() => {
                    select(item);
                    window.scrollTo(0, 0); // Scrolls to the top without smooth animation
                  }}
                  loading="lazy"
                  src={`${IMAGE_URL}${get(images, item, "")}`}
                  className={`${style.assetOption} ${
                    active === item && style.activeFrame
                  }`}
                  alt="Portait"
                  width={40}
                />

                {index === 3 ? (
                  <span>free</span>
                ) : showPrice ? (
                  <span>+{get(price, item, "")}€</span>
                ) : (
                  <span>free</span>
                )}
                {index === 2 && <p className={style.bestseller}>BEST SELLER</p>}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}

export function PortraitTypes({ types, active, select ,lng}) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [image, setImage] = useState("");
  const [secondImg, setSecondImg] = useState("");
  const [imageMobile, setImageMobile] = useState("");
  const [secondImageMob, setsecondImageMob] = useState("");
  const [firstTitle, setFirstTitle] = useState("");
  const [secondTitle, setSecondTitle] = useState("");

  const handleOpenPopup = (
    simage,
    imageMob,
    secondI,
    firstName,
    secondName,
    mobImage
  ) => {
    setIsPopupOpen(true);
    setImage(simage);
    setImageMobile(imageMob);
    setSecondImg(secondI);
    setFirstTitle(firstName);
    setSecondTitle(secondName);
    setsecondImageMob(mobImage)
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <p className={style.attributesHeading}>{label[lng].choosePortrait}</p>
      <div className={style.selectFrameDis}>
        {types.map((item, index) => (
          <div
            onClick={() => select(item)}
            className={`${style.selectFrame} ${
              active === item ? style.active : ""
            }`}
            key={index}
          >
            {item}
          </div>
        ))}
        <p
          className={style.glassDetails}
          onClick={() =>
            handleOpenPopup(
              "/images/general/korniza1.webp",
              "/images/general/mkorniza3.webp",
              "/images/general/korniza2.webp",
              "Just Canvas Details",
              "Canvas & Frame Details", "/images/general/mkorniza.webp"
            )
          }
        >
        {label[lng].viewProduct}
        </p>
        <p
          className={style.canvasDetails}
          onClick={() =>
            handleOpenPopup(
              "/images/general/korniza3.webp",
              "/images/general/mkorniza2.webp",
              "",
              "Glass & Frame Details"
            )
          }
        >
       {label[lng].viewProduct}
        </p>
        {isPopupOpen && (
          <Popup
            sliderMob="mobileSlider"
            secondFrame={secondImg}
            firstTitle={firstTitle}
            secondTitle={secondTitle}
            imageSrc={image}
            imageMob={imageMobile}
            onClose={handleClosePopup}
            secondMob={secondImageMob}
          />
        )}
      </div>
    </>
  );
}

export function Theme({ themes, active, select ,lng}) {
  return (
    <div className="borderTop">
      <p className={style.attributesHeading}>{label[lng].selectAvatar}</p>
      <div className={`grid4`}>
        {themes.map((item, index) => {
          return (
            <div
              className={`${style.assetSingle} ${
                active === item && style.active
              }`}
              key={`theme-${item}`}
            >
              <img
                src={`../images/assets/theme/${item}.webp`}
                alt={item}
                onClick={() => {
                  select(item);
                  window.scrollTo(0, 0);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function PortraitType({ types, images, active, select ,lng}) {
  return (
    <div className="borderTop">
      <p className={style.attributesHeading}>{label[lng].paintStyle}</p>
      <div className={`ptb10 flex`}>
        {types.map((item, index) => {
          return (
            <div
              className={`${style.assetSingle} ${style.pickColor} ${
                active === item && style.active
              }`}
              key={`portrait-type-${item}`}
            >
              <img
                src={`../images/assets/mug/${item}.svg  `}
                alt={item}
                onClick={() => select(item)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
